<template>
  <div class="flex flex-col mt-8">
    <c-battle-event-filter @filter-change="updateFilter" :initialAttackChecked="attackChecked" :initialDefenseChecked="defenseChecked" />
    <div v-for="(item, ind) in tableData" :key="ind" class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <thead>
            <tr>
              <th class="w-1/12 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                #</th>
              <th class="w-1/5 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                ATTACKER [{{item.attackGuild.name}}]</th>
              <th class="w-1/5 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                DEFENDER [{{item.defGuild.name}}]</th>
              <th class="w-1/12 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                RES</th>
              <th class="w-1/12 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                STARS</th>
              <th class="w-1/12 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                DATE</th>
              <th class="w-1/6 px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                PLACE</th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr :class="getBgColor(item)">
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 30px;">
              {{ind+1}}
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span class="p-2 text-black"><b>{{item.attacker}}</b></span>
              <span class="p-2 text-black">{{item.attackerPower}}</span>
              <c-hero-pack v-if="isHero(item.attackers[0]) || isPet(item.attackers[0])" :packInfo="item.attackers" :bannerInfo="item.attackerBanner" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span class="p-2 text-black"><b>{{item.defender}}</b></span>
              <span class="p-2 text-black">{{item.defenderPower}}</span>
              <c-hero-pack v-if="isHero(item.defenders[0]) || isPet(item.defenders[0])" :packInfo="item.defenders" :bannerInfo="item.defenderBanner" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.res}}
            </td>
            <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 30px;">
              {{item.stars}}
            </td>
            <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.startDatetime}}
            </td>
            <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 90px;">
              {{placeInfo(item)}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HeroPack from "@src/components/hero/HeroPack.vue";
import BattleEventFilter from "@src/components/BattleEventFilter.vue";

export default {
    name: `c-arena-battle-table`,
    components: {
      'c-hero-pack': HeroPack,
      'c-battle-event-filter': BattleEventFilter,
    },
    props: {
      battleInfo: {
        type: Array,
        require: true,
      },
      masterUserName: {
        type: String,
        require: true,
      },
    },
    data() {
      return {
        attackChecked: true,
        defenseChecked: false,
      };
    },
    computed: {
      tableData() {
        return this.battleInfo.filter(item => {
          if (this.attackChecked && this.defenseChecked) {
            return true;
          } else if (this.attackChecked) {
            return this.isMasterUserAttacker(item)
          } else if (this.defenseChecked) {
            return this.isMasterUserDefender(item);
          }
          return false;
        })
      },
    },
    methods: {
      isHero(item) {
        return item && item.type && item.type === 'hero'
      },
      isPet(item) {
        return item && item.type && item.type === 'pet'
      },
      isTitan(item) {
        return item && item.type && item.type === 'titan'
      },
      getBgColor(item) {
        switch (item.cssStyle) {
          case 'red':
            return 'bg-rose-300'
          case 'blue':
            return 'bg-sky-300'
          case 'green':
            return 'bg-emerald-300'
          default:
            return ''
        }
      },
      placeInfo(item) {
        if (item.newPlace && item.oldPlace) {
          if (item.res === 'win') {
            return item.oldPlace + ' -> ' + item.newPlace
          } else {
            return item.newPlace + ' -> ' + item.oldPlace
          }
        } else {
          if (!item.newPlace) {
            return 'remain'
          }
        }
        return ''
      },
      updateFilter({ attackChecked, defenseChecked }) {
        this.attackChecked = attackChecked;
        this.defenseChecked = defenseChecked;
      },
      isMasterUserAttacker(item) {
        //TODO Как быть если userName обоих совпадут?
        return item.attacker && item.attacker === this.masterUserName
      },
      isMasterUserDefender(item) {
        //TODO Как быть если userName обоих совпадут?
        return item.defender && item.defender === this.masterUserName
      },
    },
    mounted() {
    },
}
</script>

<style scoped>
.hero-pack {
    width: 500px;
}
.hero-item {
    display: inline-block;
}

</style>
