<template>
  <div >
    <div class="py-2 -my-2 overflow-x-auto mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <div :class="getBgColor(event)">{{getData}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroPack from "@src/components/hero/HeroPack.vue";
import TitanPack from "@src/components/hero/TitanPack.vue";
import ClanIcon from "@src/components/ClanIcon.vue";

export default {
    name: `c-stats-player`,
    components: {
      // 'c-clan-icon': ClanIcon,
      // 'c-hero-pack': HeroPack,
      // 'c-titan-pack': TitanPack,
    },
    props: {
      event: {
        type: Object,
        require: true,
      },
      userList: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
        // heroIcon: '/img/0004.png',
        // heroIconBorder: '/img/red_2.png',
        // bigStarIcon: '/img/bigStarIcon.png',
        // heroLevel: '/img/level_red.png',
        // patronagePetIcon: '/img/6007.png',
      };
    },
    computed: {
      getData() {
        if (this.event === undefined) {
          return ''
        }

        const attacker = this.findUser(this.event.AttackerID)
        const defender = this.findUser(this.event.DefenderID)
        const attackerName = attacker ? attacker.Name : 'none'
        const defenderName = defender ? defender.Name : 'none'

        const result = this.event.Win ? 'win' : 'loss'
        // return attacker.Name + '  ' + this.event.AttackerTotalPower + ' vs ' + defender.Name + ' ' + this.event.DefenderTotalPower + ' = ' + this.event.SlotPoints + ' + ' + this.event.FortificationPoints
        return attackerName + ' vs ' + defenderName + ' = ' + this.event.SlotPoints + ' ' + result
      },

      // attackScoreData() {
      //   return this.battleInfo.attackScoreData
      // },
      // getTable2() {
      //   return this.battleInfo.tableData2
      // },
      // opponentAttackScoreData() {
      //   return this.battleInfo.opponentAttackScoreData
      // },
    },
    methods: {
      findUser(id) {
        if (this.userList !== undefined && this.userList[id]) {
          return this.userList[id]
        }

        return undefined
      },
      getBgColor(item) {
        if (item.Win) {
          if (item.SlotPoints === 20) {
            return 'bg-emerald-300'
          } else {
            return 'bg-sky-300'
          }
        } else {
          return 'bg-rose-300'
        }
      },
      // isPet(item) {
      //   return item && item.type && item.type === 'pet'
      // },
      // isTitan(item) {
      //   return item && item.type && item.type === 'titan'
      // },
      // getBgColor(item) {
      //   switch (item.cssStyle) {
      //     case 'red':
      //       return 'bg-rose-300'
      //     case 'blue':
      //       return 'bg-sky-300'
      //     case 'green':
      //       return 'bg-emerald-300'
      //     default:
      //       return ''
      //   }
      // },
    },
    mounted() {
    },
}
</script>

<style scoped>
.stats-player {
  display: flex;
  align-items: flex-start;
}

</style>
