<template>
  <c-auth></c-auth>
</template>

<script>
import Auth from '@src/components/Auth.vue'

export default {
  components: {
    'c-auth': Auth,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}

.form {
  display: inline-block;
  vertical-align: top;
}

</style>
