<template>
  <div class="d-block">
    <div class="avatar-icon">
      <img :src="avatarIcon" class="image" />
      <img :src="frame" class="frame" />
      <c-team-level v-if="teamLevel" :level="teamLevel" :color="teamIconBackgroundColor" />
    </div>
<!--    <div class="portal">
      <div class="portal-header">
        <span>{{userName}}</span>
      </div>
    </div>-->
  </div>
</template>

<script>
import TeamLevel from "@src/components/TeamLevel.vue";

export default {
  name: `c-avatar`,
  components: {
    'c-team-level': TeamLevel,
  },
  props: {
    avatarId: {
      type: [String, Number],
      require: true,
    },
    frameId: {
      type: [String, Number],
      require: true,
    },
    userName: {
      type: String,
      require: true,
    },
    teamLevel: {
      type: [String, Number],
      require: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    avatarIcon() {
      return '/img/avatar/' + this.avatarId + '.png';
    },
    frame() {
      return '/img/frames/' + this.frameId + '.png';
    },
    teamIconBackgroundColor() {
      // return this.convertColorToShortName(this.heroInfo.color)
      return 'blue'
    },
  },
  methods: {
    // convertColorToName,
    // convertColorToShortName,
    // calcPercent
  },
  mounted() {
  },
}
</script>

<style scoped>

.d-block {
  display: inline-block;
}

.image-frame {
  margin-left: 2px;
  margin-top: 2px;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  display: block;
}

.avatar-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  width: 80px;
  height: 80px;
}

img {
  vertical-align: middle;
  border-style: none;
}

img.frame {
  position: absolute;
  top: -12px;
  left: -10px;
  width: 128%;
  height: 128%;
  max-width: none;

  z-index: 50;
}

img.image {
  position: absolute;
  /*top: 0;*/
  /*left: 0;*/
  /*width: 90%;*/
  /*height: 90%;*/
  /*margin: 5px;*/
  border-radius: 45%;
}

.portal-header > span {
  height: 58px;
  font-size: 26px;
  padding: 5px 0;
  background: url(img/header-body.png) no-repeat;
  color: white;
}
.portal-header::before, .portal-header::after {
  display: block;
  content: '';
  height: 58px;
  width: 101px;
  background: url(img/header-decor.png) no-repeat;
}
/*.portal-header::after {*/
/*  display: block;*/
/*  content: '';*/
/*  height: 58px;*/
/*  width: 101px;*/
/*  background-image: url(img/header-decor.png);*/
/*}*/

</style>
