<template>
  <div class="d-block">
    <div class="hero-icon">
      <c-hero-icon :id="heroId" width="60" height="60" :backgroundColor="heroIconBackgroundColor" class="icon"/>
      <svg class="border">
        <image :href="heroIconBorder" width="60px" height="60px"/>
      </svg>
      <svg v-if="patronagePetIcon !== ''" class="patronage">
        <image :href="patronagePetIcon" width="25px" height="25px"/>
      </svg>
      <c-hero-level :level="heroLevel" :color="heroIconBackgroundColor" class="level"/>
      <c-hero-stars :stars="heroStars" class="stars"/>
    </div>
    <c-item-xp v-if="heroInfo" :xp="heroInfo.power"/>
    <c-progress-bar v-if="heroInfo.result" :progressPercent="calcPercent(heroInfo.hp, heroInfo.result.hp)" bgColor="emerald" />
    <c-progress-bar v-if="heroInfo.result" :progressPercent="calcPercent(1000, heroInfo.result.energy)" bgColor="amber" />
  </div>
</template>

<script>
import ProgressBar from "@src/components/ProgressBar.vue"
import HeroIcon from "@src/components/hero/HeroIcon.vue";
import HeroStars from "@src/components/hero/HeroStars.vue";
import HeroLevel from "@src/components/hero/HeroLevel.vue";
import ItemXp from "@src/components/hero/ItemXp.vue";

export default {
  components: {
    'c-hero-icon': HeroIcon,
    'c-hero-stars': HeroStars,
    'c-hero-level': HeroLevel,
    'c-item-xp': ItemXp,
    'c-progress-bar': ProgressBar,
  }
}
</script>


<script setup>
import {convertColorToName, convertColorToShortName, calcPercent} from "@src/components/common.ts"
import {computed} from 'vue'

const props = defineProps({
  heroInfo: {
    type: Object,
    required: true,
  },
})

const heroId = computed(() => {
  return String(props.heroInfo.id).padStart(4, "0")
})

const heroIconBackgroundColor = computed(() => {
  return convertColorToShortName(props.heroInfo.color)
})

const heroIconBorder = computed(() => {
  const colorName = convertColorToName(props.heroInfo.color)
  return `/img/${colorName}.png`
})

const heroLevel = computed(() => props.heroInfo.level)

const heroStars = computed(() => props.heroInfo.star)

const patronagePetIcon = computed(() => {
  if (!props.heroInfo || !props.heroInfo.petId || props.heroInfo.petId === 0) {
    return ''
  }
  return `/img/${props.heroInfo.petId}.png`
})

</script>

<style scoped>
.hero-icon {
  position: relative;
  width: 60px;
  height: 60px;
}

.patronage {
  position: absolute;
  top: -8px;
  left: -5px;
  width: 25px;
  height: 25px;
  z-index: 90;
}

.level {
  position: absolute;
  top: 1px;
  left: 16px;
}

.stars {
  position: absolute;
  bottom: -1px;
  left: 0px;
}

.border, .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-width: 0px;
}

.icon {
  padding-top: 4px;
  padding-left: 4px;
  /*border-style: none;*/
  width: 56px;
  height: 56px;
}
</style>
