<template>
  <div class="d-block mx-3 clan-icon">
    <svg viewBox="0 0 102 142" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="flag" width="100%" height="100%">
          <image href="https://st.proxima-tech.ru/img/avatar/clanIcons.png" x="-226" y="-150" width="894" height="512" />
        </pattern>

        <pattern id="flag-border" width="100%" height="100%">
          <image href="https://st.proxima-tech.ru/img/avatar/clanIcons.png" x="-226" y="-293" width="894" height="512" />
        </pattern>

        <pattern id="flag-leader-border" width="100%" height="100%">
          <image href="https://st.proxima-tech.ru/img/avatar/clan-leader.png" x="0" y="0" width="102" height="142" />
        </pattern>


        <filter id="colorOverlay" x="0" y="0" width="100%" height="100%">
          <feFlood flood-color="#2e2e2e" result="color"/>
          <feBlend in="SourceGraphic" in2="color" mode="multiply"/>
        </filter>

        <g :id="'flag-shape-pattern-' + clanIcon.flagShape" v-html="getFlagShapePattern(clanIcon.flagShape)"></g>
        <g :id="'icon-shape-pattern-' + clanIcon.iconShape" v-html="getIconShapePattern(clanIcon.iconShape)"></g>
      </defs>

      <rect width="102" height="142" fill="url(#flag)" />
      <rect width="102" height="142" v-bind:fill="getColorById(clanIcon.flagColor2)" v-bind:mask="getUrlFlagShapeInvertedMask(clanIcon.flagShape)" style="mix-blend-mode: multiply;" />
      <rect width="102" height="142" v-bind:fill="getColorById(clanIcon.flagColor1)" v-bind:mask="getUrlFlagShapeMask(clanIcon.flagShape)" style="mix-blend-mode: multiply;"/>

      <rect x="3" y="-1" width="102" height="142" fill="url(#flag-border)" />



      <rect v-if="isSmallIcon(clanIcon.iconShape)" x="26" y="40" width="53" height="57" v-bind:fill="getUrlIconShape(clanIcon.iconShape)" />
      <rect v-else x="22" y="35" width="60" height="66" v-bind:fill="getUrlIconShape(clanIcon.iconShape)" />

      <rect v-if="clanLeader" x="0" y="0" width="102" height="142" fill="url(#flag-leader-border)" />
    </svg>
  </div>
</template>

<script>
import TeamLevel from "@src/components/TeamLevel.vue";
import {createFlagShapePattern, createIconShapePattern, getColorById} from './common.ts'

export default {
  name: `c-clanIcon`,
  // components: {
  //   'c-team-level': TeamLevel,
  // },
  props: {
    clanIcon: {
      type: Object,
      require: true,
    },
    clanLeader: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    // avatarIcon() {
    //   return '/img/avatar/clanIcons.png';
    // },
    // frame() {
    //   return '/img/frame/' + this.frameId + '.png';
    // },
    teamIconBackgroundColor() {
      // return this.convertColorToShortName(this.heroInfo.color)
      return 'blue'
    },
    getClanLeader() {
      return this.clanLeader ? 'clan-leader' : ''
    },
  },
  methods: {
    getFlagShapePattern(shapeId) {
      return createFlagShapePattern(shapeId)
    },
    getIconShapePattern(shapeId) {
      return createIconShapePattern(shapeId)
    },
    getUrlFlagShapeMask(iconShape) {
      return 'url(#mask-flag-shape-' + iconShape + ')'
    },
    getUrlFlagShapeInvertedMask(iconShape) {
      return 'url(#inverted-mask-flag-shape-' + iconShape + ')'
    },
    getUrlIconShape(iconShape) {
      return 'url(#icon-shape-' + iconShape + ')'
    },
    getColorById(colorId) {
      return getColorById(colorId)
    },
    isSmallIcon(iconShape) {
      switch (iconShape) {
        case 4:
        case 20:
        case 37:
          return true
        default:
          return false
      }
    },
  },
}
</script>

<style scoped>

.d-block {
  display: inline-block;
}

.image-frame {
  margin-left: 2px;
  margin-top: 2px;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  display: block;
}

.clan-icon {
  /*display: flex;*/
  /*justify-content: center;*/
  /*flex-direction: column;*/
  /*position: relative;*/

  width: 70px;
  height: 100px;
}

/*.flag-container {*/
/*  width: 100px;*/
/*  height: 140px;*/
/*  background: url(img/avatar/clanIcons.png) -228px -150px;*/
/*}*/

/*.clan-leader{*/
/*  width: 70px;*/
/*  height: 98px;*/
/*  background: url(img/avatar/clan-leader.png);*/
/*}*/

/*.icon-container {*/
/*  width: 78px;*/
/*  height: 65px;*/
/*  background: url(img/avatar/clanIcons.png) -331px -183px;*/
/*}*/

.icon-color1 {
  filter: saturate(2) grayscale(33%) contrast(129%) brightness(0.85) invert(11%) sepia(92%) hue-rotate(191deg);
}

.icon-color-gold {
  filter: saturate(2) grayscale(7%) contrast(148%) brightness(0.85) invert(10%) sepia(100%) hue-rotate(13deg);}

.saturate { filter: saturate(3); }
.grayscale { filter: grayscale(100%); }
.contrast { filter: contrast(160%); }
.brightness { filter: brightness(0.25); }
.blur { filter: blur(3px); }
.invert { filter: invert(100%); }
.sepia { filter: sepia(100%); }
.huerotate { filter: hue-rotate(180deg); }
.rss.opacity { filter: opacity(50%); }

img {
  vertical-align: middle;
  border-style: none;
}

img.frame {
  position: absolute;
  top: -7px;
  left: -7px;
  width: 120%;
  height: 120%;
  max-width: none;

  z-index: 50;
}

img.image {
  position: absolute;
  /*top: 0;*/
  /*left: 0;*/
  /*width: 90%;*/
  /*height: 90%;*/
  /*margin: 5px;*/
  border-radius: 45%;
}

/*.portal-header > span {*/
/*  height: 58px;*/
/*  font-size: 26px;*/
/*  padding: 5px 0;*/
/*  background: url(img/header-body.png) no-repeat;*/
/*  color: white;*/
/*}*/
/*.portal-header::before, .portal-header::after {*/
/*  display: block;*/
/*  content: '';*/
/*  height: 58px;*/
/*  width: 101px;*/
/*  background: url(img/header-decor.png) no-repeat;*/
/*}*/
/*.portal-header::after {*/
/*  display: block;*/
/*  content: '';*/
/*  height: 58px;*/
/*  width: 101px;*/
/*  background-image: url(img/header-decor.png);*/
/*}*/

</style>
