<template>
  <template v-for="(coords, id) in heroIconCoordinates" :key="id">
    <symbol
        :id="`hero-${id}`"
        :viewBox="`${coords.x} ${coords.y} 160 160`"
    >
      <image href="/img/hero/hero_icons.webp" x="0" y="0" width="1783" height="973" />
    </symbol>
  </template>
</template>

<script setup>
const heroIconCoordinates = {
  "0001": { x:"2", y:"2"},
  "0002": { x:"2", y:"164"},
  "0003": { x:"2", y:"326"},
  "0004": { x:"2", y:"488"},
  "0005": { x:"2", y:"650"},
  "0006": { x:"2", y:"812"},
  "0007": { x:"164", y:"2"},
  "0008": { x:"164", y:"164"},
  "0009": { x:"164", y:"326"},
  "0010": { x:"164", y:"488"},
  "0011": { x:"164", y:"650"},
  "0012": { x:"164", y:"812"},
  "0013": { x:"326", y:"2"},
  "0014": { x:"326", y:"164"},
  "0015": { x:"326", y:"326"},
  "0016": { x:"326", y:"488"},
  "0017": { x:"326", y:"650"},
  "0018": { x:"326", y:"812"},
  "0019": { x:"488", y:"2"},
  "0020": { x:"488", y:"164"},
  "0021": { x:"488", y:"326"},
  "0022": { x:"488", y:"488"},
  "0023": { x:"488", y:"650"},
  "0024": { x:"488", y:"812"},
  "0025": { x:"650", y:"2"},
  "0026": { x:"650", y:"164"},
  "0027": { x:"650", y:"326"},
  "0028": { x:"650", y:"488"},
  "0029": { x:"650", y:"650"},
  "0030": { x:"650", y:"812"},
  "0031": { x:"812", y:"2"},
  "0032": { x:"974", y:"2"},
  "0033": { x:"1136", y:"2"},
  "0034": { x:"1298", y:"2"},
  "0035": { x:"1460", y:"2"},
  "0036": { x:"1622", y:"2"},
  "0037": { x:"812", y:"164"},
  "0038": { x:"812", y:"326"},
  "0039": { x:"812", y:"488"},
  "0040": { x:"812", y:"650"},
  "0041": { x:"812", y:"812"},
  "0042": { x:"974", y:"164"},
  "0043": { x:"1136", y:"164"},
  "0044": { x:"1298", y:"164"},
  "0045": { x:"1460", y:"164"},
  "0046": { x:"1622", y:"164"},
  "0047": { x:"974", y:"326"},
  "0048": { x:"974", y:"488"},
  "0049": { x:"974", y:"650"},
  "0050": { x:"974", y:"812"},
  "0051": { x:"1136", y:"326"},
  "0052": { x:"1298", y:"326"},
  "0053": { x:"1460", y:"326"},
  "0054": { x:"1622", y:"326"},
  "0055": { x:"1136", y:"488"},
  "0056": { x:"1136", y:"650"},
  "0057": { x:"1136", y:"812"},
  "0058": { x:"1298", y:"488"},
  "0059": { x:"1460", y:"488"},
  "0060": { x:"1622", y:"488"},
  "0061": { x:"1298", y:"650"},
  "0062": { x:"1298", y:"812"},
  "0063": { x:"1460", y:"650"},
  "0064": { x:"1622", y:"650"},
}
</script>