<template>
  <div class="battle-enemies">
    <template v-for="(item, ind) in enemies" :key="ind">
      <div class="d-block" v-on:click="onClickCallback(item.user.id)">
        <div v-if="isDevMode"> frameId: {{item.user.frameId}} </div>
        <div v-if="isDevMode"> avatarId: {{item.user.avatarId}} </div>
        <div v-if="isDevMode"><pre>clanIcon: {{JSON.stringify(item.user.clanIcon, null, 2)}} </pre></div>

        <c-user :userData="item.user" class="d-block"/>
        <div>Place: {{item.place}}</div>
        <div>Power: {{item.power}} </div>
        <div v-if="item.heroes !== undefined && isArray(item.heroes)">
          <div v-for="(itemPack, idx) in item.heroes" :key="idx">
            <c-hero-pack :packInfo="itemPack" style="width: 380px;" class="d-block"/>
          </div>
        </div>
        <div v-else>
          <c-hero-pack :packInfo="getPack(item)" style="width: 380px;" class="d-block"/>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import {
  isArray,
  first,
} from "lodash"
import User from "@src/components/User.vue";
import HeroPack from "@src/components/hero/HeroPack.vue";

export default {
  name: `battle-enemies`,
  components: {
    'c-user': User,
    'c-hero-pack': HeroPack,
  },
  props: {
    enemies: {
      type: Array,
      require: true,
    },
    onClickCallback: {
      type: Function,
      require: true,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
  methods: {
    isArray(item) {
      return isArray(item) && isArray(first(item))
    },
    getPack(item) {
      if (item === undefined || item.heroes === undefined) {
        return []
      }
      return item.heroes
    },
  },
  mounted() {
  },
}
</script>

<style scoped>

.d-block {
  display: inline-block;
}

/*.hero-pack {*/
/*  !*width: 350px;*!*/
/*  height: 90px;*/
/*}*/
/*.hero-item {*/
/*  display: inline-block;*/
/*  vertical-align: top;*/
/*}*/

</style>
