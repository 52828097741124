<template>
  <template v-for="(coords, id) in titanIconCoordinates" :key="id">
    <symbol
        :id="`titan-${id}`"
        :viewBox="`${coords.x} ${coords.y} 84 84`"
    >
      <image href="/img/titan/titan_icons.webp" x="0" y="0" width="1003" height="893" />
    </symbol>
  </template>
</template>

<script setup>
const titanIconCoordinates = {
  "4000": { x:"486", y:"668"},
  "4001": { x:"572", y:"496"},
  "4002": { x:"572", y:"582"},
  "4003": { x:"830", y:"410"},
  "4010": { x:"746", y:"410"},
  "4011": { x:"916", y:"410"},
  "4012": { x:"572", y:"410"},
  "4013": { x:"486", y:"754"},
  "4020": { x:"658", y:"410"},
  "4021": { x:"486", y:"410"},
  "4022": { x:"916", y:"324"},
  "4023": { x:"486", y:"580"},
  "4030": { x:"324", y:"648"},
  "4031": { x:"658", y:"324"},
  "4032": { x:"830", y:"324"},
  "4033": { x:"486", y:"324"},
  "4040": { x:"324", y:"734"},
  "4041": { x:"572", y:"324"},
  "4042": { x:"486", y:"496"},
  "4043": { x:"744", y:"324"},
}
</script>