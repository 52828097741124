import {
    getFortNameBySlotId,
    calcPackTotalPower,
    updateUsefulPoints,
    getAttackerAndDefenderLists,
    calcPackTotalPowerNew,
    getAttackerAndDefenderListsNew,
    extractClanTitle
} from './common.ts'

export function extractData(eventData) {
    if (eventData['arenaEvents'] === undefined || eventData['users'] === undefined) {
        return undefined
    }
    return {'users': eventData['users'], 'replays': eventData['arenaEvents']}
}

export function createArenaData(eventData, masterUserId) {
    const sourceData = extractData(eventData)
    if (sourceData === undefined) {
        return undefined
    }

    let tableData = []
    for (const key in sourceData['replays']) {
        const replay = sourceData['replays'][key]
        if (replay['type']  !== 'arena') {
            throw new Error('invalid Arena type: ' + replay['type'])
        }

        const attackerId = replay['userId']
        const defenderId = replay['typeId']

        let attackerName = ''
        let attackGuild = undefined
        // const attackerName = sourceData['users'][attackerId]['name']
        const attacker = sourceData['users'].filter(item => item['id'] === attackerId)
        if (attacker && attacker[0]) {
            attackerName = attacker[0]['name']
            const attackGuildName = extractClanTitle(attacker[0])
            if (attackGuildName !== undefined) {
                attackGuild = {name: attackGuildName, clanIcon: attacker[0]['clanIcon']}
            }
        }

        let defenderName = ''
        let defGuild = undefined
        // const defenderName = sourceData['users'][defenderId]['name']
        const defender = sourceData['users'].filter(item => item['id'] === defenderId)
        if (defender && defender[0]) {
            defenderName = defender[0]['name']
            const defGuildName = extractClanTitle(defender[0])
            if (defGuildName !== undefined) {
                defGuild = {name: defGuildName, clanIcon: defender[0]['clanIcon']}
            }
        }

        const startDateTime = new Date(replay['startTime'] * 1000).toLocaleDateString("ru-RU", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).toString()

        let win = !!replay['result']['win']

        // Если владелец аккаунта защищается, то поражение это его победа
        if (defenderId === masterUserId) {
            win = !win
        }
        let res = win ? 'win' : 'loss'
        const stars = replay['result']['stars']

        const oldPlace = replay['result']['oldPlace']
        const newPlace = replay['result']['newPlace']
        const enemyPlace = replay['result']['enemyPlace']

        const rowData = {'attacker' : attackerName, 'attackGuild' : attackGuild,
            'defender' : defenderName, 'defGuild' : defGuild, 'res' : res,
            'oldPlace': oldPlace, 'newPlace': newPlace, 'enemyPlace': enemyPlace, 'stars': stars,
            'startDatetime': startDateTime
        }

        const packTotalPower = calcPackTotalPowerNew(replay)
        const battleParticipantList = getAttackerAndDefenderListsNew(replay)

        rowData['cssStyle'] = res === 'win' ? 'green' : 'red'
        rowData['attackerPower'] = packTotalPower[0]
        rowData['attackers'] = battleParticipantList['attackerList']
        rowData['defenderPower'] = packTotalPower[1]
        rowData['defenders'] = battleParticipantList['defenderList']

        //Флаги
        if (replay["effects"] && replay["effects"]["attackersBanner"] && replay["effects"]["attackersBanner"]["id"] > 0) {
            rowData["attackerBanner"] = replay["effects"]["attackersBanner"]
        }
        if (replay["effects"] && replay["effects"]["defendersBanner"] && replay["effects"]["defendersBanner"]["id"] > 0) {
            rowData["defenderBanner"] = replay["effects"]["defendersBanner"]
        }

        tableData.push(rowData)
    }

    return tableData
}