import { createRouter, createWebHistory/*, createWebHashHistory*/ } from 'vue-router'
// import Statistics from '@src/components/Statistics.vue'
import Auth from '@src/pages/Auth.vue'
import Arena from '@src/pages/Arena.vue'
import GrandArena from '@src/pages/GrandArena.vue'
import ClanWar from '@src/pages/ClanWar.vue'
import Stats from '@src/pages/Stats.vue'

const routes = [
    { path: '/stats', component: Stats },
    { path: '/auth', component: Auth },
    { path: '/arena', component: Arena },
    { path: '/ga', component: GrandArena },
    { path: '/cw', component: ClanWar },
]

if (process.env.NODE_ENV === 'development') {
    const ClanDomination = () => import('@src/pages/ClanDomination.vue')
    routes.push({ path: '/cd', component: ClanDomination })
}

export const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    routes,
    default: '/',
})

// router.beforeEach((to, from, next) => {
//     // const token = localStorage.getItem('token');
//     const token = getToken();
//
//     if (token === undefined && to.name !== 'auth') {
//         next({ name: 'auth' });
//     } else {
//         if (isAuthenticated()) {
//             // Если токен истек, показать форму авторизации поверх текущей страницы
//             // Можно использовать компонент или модальное окно для этого
//             // Например:
//             // store.commit('setShowAuthForm', true);
//         }
//
//         next();
//     }
// });
