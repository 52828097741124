<template>
  <div class="flex flex-col mt-8">
    <c-battle-event-filter @filter-change="updateFilter" :initialAttackChecked="attackChecked" :initialDefenseChecked="defenseChecked" />
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <tbody class="bg-white">
          <template v-for="(group, index) in groupedData" :key="index">
            <template v-if="groupedPlaceInfo[group[0].startDatetime]">
              <tr>
                <td :colspan="2" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  ATTACKER [{{ groupedPlaceInfo[group[0].startDatetime].attackGuild.name }}]
                </td>
                <td :colspan="1" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  DEFENDER [{{ groupedPlaceInfo[group[0].startDatetime].defGuild.name }}]
                </td>
                <td :colspan="1" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  RES
                </td>
                <td :colspan="1" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  STARS
                </td>
                <td :colspan="1" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  DATETIME
                </td>
                <td :colspan="1" class="px-2 py-2 text-xs font-medium leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                  PLACE
                </td>
              </tr>
            </template>
            <template v-for="(item, ind) in group" :key="ind">
              <tr :class="getBgColor(item)">
                <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 30px;">
                  {{ind+1}}
                </td>
                <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                  <span class="p-2 text-black"><b>{{item.attacker}}</b></span>
                  <span class="p-2 text-black">{{item.attackerPower}}</span>
                  <c-hero-pack v-if="isHero(item.attackers[0]) || isPet(item.attackers[0])" :packInfo="item.attackers" :bannerInfo="item.attackerBanner"/>
                </td>
                <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                  <span class="p-2 text-black"><b>{{item.defender}}</b></span>
                  <span class="p-2 text-black">{{item.defenderPower}}</span>
                  <c-hero-pack v-if="isHero(item.defenders[0]) || isPet(item.defenders[0])" :packInfo="item.defenders" :bannerInfo="item.defenderBanner"/>
                </td>
                <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200">
                  {{item.res}}
                </td>
                <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200">
                  {{item.stars}}
                </td>
                <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200">
                  {{ groupedPlaceInfo[group[0].startDatetime].startDatetime }}
                </td>
                <td class="px-2 py-2 text-lg leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200">
                  {{ groupedPlaceInfo[group[0].startDatetime].placeInfo }}
                </td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HeroPack from "@src/components/hero/HeroPack.vue";
import BattleEventFilter from "@src/components/BattleEventFilter.vue";

export default {
    name: `c-grand-arena-battle-table`,
    components: {
      'c-hero-pack': HeroPack,
      'c-battle-event-filter': BattleEventFilter,
    },
    props: {
      battleInfo: {
        type: Array,
        require: true,
      },
      masterUserName: {
        type: String,
        require: true,
      },
    },
    data() {
      return {
        colSpan: 7,
        attackChecked: true,
        defenseChecked: false,
      };
    },
    computed: {
      // getTable() {
      tableData() {
        return this.battleInfo.filter(item => {
          if (this.attackChecked && this.defenseChecked) {
            return true;
          } else if (this.attackChecked) {
            return this.isMasterUserAttacker(item)
          } else if (this.defenseChecked) {
            return this.isMasterUserDefender(item);
          }
          return false;
        })
      },
      groupedData() {
        const grouped = this.groupByStartDatetime(this.tableData);
        return Object.values(grouped);
      },
      groupedPlaceInfo() {
        const self = this
        const grouped = self.groupByStartDatetime(this.tableData);
        const placeInfoMap = {};
        Object.keys(grouped).forEach((key) => {
          const firstItem = grouped[key][0];
          const placeInfo = self.getPlaceInfo(grouped[key])

          // placeInfoMap[firstItem.startDatetime] = this.placeInfo(firstItem);
          placeInfoMap[firstItem.startDatetime] = {startDatetime: firstItem.startDatetime, attackGuild: firstItem.attackGuild, defGuild: firstItem.defGuild, placeInfo: placeInfo}
        });
        return placeInfoMap;
      },
    },
    methods: {
      getPlaceInfo(array) {
        const self = this
        let result = 'remain'
        array.forEach((item) => {
          const placeInfo = self.placeInfo(item)
          if (placeInfo !== '') {
            result = placeInfo
          }
        })

        return result
      },
      // shouldShowHeader(index) {
      //   // Check if it's the first row or if startDatetime is different from the previous row
      //   return index === 0 || this.tableData[index].startDatetime !== this.tableData[index - 1].startDatetime;
      // },

      isHero(item) {
        return item && item.type && item.type === 'hero'
      },
      isPet(item) {
        return item && item.type && item.type === 'pet'
      },
      isTitan(item) {
        return item && item.type && item.type === 'titan'
      },
      getBgColor(item) {
        switch (item.cssStyle) {
          case 'red':
            return 'bg-rose-300'
          case 'blue':
            return 'bg-sky-300'
          case 'green':
            return 'bg-emerald-300'
          default:
            return ''
        }
      },
      placeInfo(item) {
        if (item.newPlace && item.oldPlace) {
          if (item.res === 'win') {
            return item.oldPlace + ' -> ' + item.newPlace
          } else {
            return item.newPlace + ' -> ' + item.oldPlace
          }
        } else {
          if (!item.newPlace) {
            return ''
          }
        }
        return ''
      },

      groupByStartDatetime(data) {
        return data.reduce((acc, item) => {
          if (!acc[item.startDatetime]) {
            acc[item.startDatetime] = [];
          }
          acc[item.startDatetime].push(item);
          return acc;
        }, {});
      },
      updateFilter({ attackChecked, defenseChecked }) {
        this.attackChecked = attackChecked;
        this.defenseChecked = defenseChecked;
      },
      isMasterUserAttacker(item) {
        //TODO Как быть если userName обоих совпадут?
        return item.attacker && item.attacker === this.masterUserName
      },
      isMasterUserDefender(item) {
        //TODO Как быть если userName обоих совпадут?
        return item.defender && item.defender === this.masterUserName
      },
    },
    mounted() {
    },
}
</script>

<style scoped>
.hero-pack {
    width: 500px;
}
.hero-item {
    display: inline-block;
}

</style>
