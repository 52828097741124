<template>
  <div class="mt-8">
    <div class="flex py-2 -my-2 overflow-x-auto mt-2">
      <div class="w-8/12">
        <table class="table p-4">
          <thead>
            <tr>
              <th v-for="(item, date) in statsInfo.attack" :key="date" class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                <div class="inline-flex">
                  <div class="px-1">{{date}}</div>
                  <div class="px-1" v-html="calcResult(item, statsInfo.defence[date])"></div>
                  <div class="px-1" v-text="getClanName(item)"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr>
            <td v-for="(item, date) in statsInfo.attack" :key="date"  class="align-top border-b border-gray-200" style="width: 20px;">
              <div v-for="(eventlist, userId) in item" :key="userId" >
                <div v-if="userId !== '0'" >
                  <c-stats-player v-for="(event, key) in eventlist" :key="key" :event="event" :userList="userList"></c-stats-player>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="w-4/12">
        <table class="table p-4">
          <thead>
            <tr>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                #</th>
              <th class="w-3/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                name</th>
              <th class="w-2/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                power heroes</th>
              <th class="w-2/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                power enemy heroes</th>
              <th class="w-2/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                power titans</th>
              <th class="w-2/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                power enemy titans</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                points</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                strikes</th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="(userStats, id) in userSortedTotalStats" :key="id" :class="getBgColor(userStats)">
            <td>{{id+1}}</td>
            <td>{{userStats.userName}}</td>
            <td>{{userStats.attackerHeroSumPower}}</td>
            <td>{{userStats.defenderHeroSumPower}}</td>
            <td>{{userStats.attackerTitanSumPower}}</td>
            <td>{{userStats.defenderTitanSumPower}}</td>
            <td>{{userStats.points}}</td>
            <td>{{userStats.strikes}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import StatsPlayer from "@src/components/StatsPlayer.vue";
import {aggregateSum, createUserTotalStats} from '@src/components/stats.ts'
// import {
//   isArray,
// } from "lodash"
import {findUserById, isFairBattle} from "./stats";

export default {
    name: `c-clan-war-stats-table`,
    components: {
      'c-stats-player': StatsPlayer,
    },
    props: {
      statsInfo: {
        type: Object,
        require: true,
      },
      statsTotalInfo: {
        type: Object,
        require: true,
      },
      userList: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
      };
    },
    computed: {
      userSortedTotalStats() {
        return this.userTotalStats.sort((a, b) => {
          return b.points - a.points;
        })
      },
      userTotalStats() {
        return createUserTotalStats(this.statsTotalInfo.attack, this.userList)
      },
      topPointsLevel() {
        return this.countBattles * 30 //160
      },
      bottomPointsLevel() {
        return this.countBattles * 20 //100
      },
      countBattles() {
        let result = 0

        const self = this
        Object.keys(self.statsInfo.attack).forEach((key) => {
          const item = self.statsInfo.attack[key]
          if (isFairBattle(item)) {
            result++
          }
        })

        return result
      }
    },
    methods: {
      getBgColor(item) {
        if (item.points >= this.topPointsLevel) {
          return 'bg-emerald-300'
        } else if(item.points < this.bottomPointsLevel) {
          return 'bg-rose-300'
        } else {
          return 'bg-sky-300'
        }
      },
      calcResult(attack, defence) {
        const attackPoints = aggregateSum(attack/*,'points'*/)
        const defencePoints = aggregateSum(defence/*,'points'*/)

        if (attackPoints > defencePoints) {
          return "<b class='text-emerald-700'>win</b>"
        } else if (attackPoints < defencePoints) {
          return "<b class='text-rose-700'>loss</b>"
        } else {
          return "<b class='text-blue-700'>draw</b>"
        }
      },
      getClanName(attack) {
        let userId = Object.keys(attack)[0]
        if (userId === '0') {
          userId = this.findNotEmptyUserId(attack)
        }

        const firstItemByUserId = attack[userId]
        const user = findUserById(firstItemByUserId[0].DefenderID , this.userList)

        return user ? user.ClanTitle : 'none'
      },
      findNotEmptyUserId(attack) {
        let userId = '0'
        Object.keys(attack).forEach((key) => {
          if (key !== '0') {
            userId = key
            return userId
          }
        })

        return userId
      }
    },
    mounted() {
    },
}
</script>

<style scoped>
.table {
  width: 98%;
}

</style>
