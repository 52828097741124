<template>
  <template v-for="(coords, id) in bannerIconCoordinates" :key="id">
    <symbol
        :id="`banner-${id}`"
        :viewBox="`${coords.x} ${coords.y} 84 84`"
    >
      <image href="/img/hero/banner_icons.png" x="0" y="0" width="431" height="87" />
    </symbol>
  </template>
</template>

<script setup>
const bannerIconCoordinates = {
  "1": { x:"1", y:"1"},
  "4": { x:"88", y:"1"},
  "5": { x:"174", y:"1"},
  "2": { x:"260", y:"1"},
  "3": { x:"346", y:"1"},
}
</script>