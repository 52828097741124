import {isObject, isArray, isNumber, isEmpty, cloneDeep} from "lodash"

const heroOrderPlace = {
    "1": 2, //Aurora
    "2": 12, //Galahad
    "3": 27, //Keira
    "4": 9, //Astaroth
    "5": 32, //Kai
    "6": 54, //Phobos
    "7": 60, //Thea
    "8": 42, //Daredevil
    "9": 38, //Heidi
    "10": 51, //Faceless
    "11": 1, //Chabba
    "12": 24, //Arachne
    "13": 40, //Orion
    "14": 52, //Fox
    "15": 41, //Ginger
    "16": 25, //Dante
    "17": 37, //Mojo
    "18": 28, //Judge
    "19": 44, //Dark_Star
    "20": 55, //Artemis
    "21": 16, //Markus
    "22": 58, //Peppy
    "23": 53, //Lian
    "24": 3, //Cleaver
    "25": 14, //Ishmael
    "26": 63, //Lilith
    "27": 5, //Luther
    "28": 20, //Qing_Mao
    "29": 56, //Dorian
    "30": 50, //Cornelius
    "31": 59, //Jet
    "32": 62, //Helios
    "33": 45, //Lars
    "34": 26, //Krista
    "35": 39, //Jorgen
    "36": 23, //Maya
    "37": 34, //Jhu
    "38": 19, //Elmir
    "39": 6, //Ziri
    "40": 35, //Nebula
    "41": 15, //K'arkh
    "42": 7, //Rufus
    "43": 30, //Celeste
    "44": 47, //Astrid_and_Lucas
    "45": 21, //Satori
    "46": 64, //Martha
    "47": 17, //Andvari
    "48": 36, //Sebastian
    "49": 18, //Yasmine
    "50": 4, //Corvus
    "51": 29, //Morrigan
    "52": 33, //Isaac
    "53": 22, //Alvanor
    "54": 13, //Tristan
    "55": 49, //Iris
    "56": 31, //Amira
    "57": 61, //Fafnir
    "58": 48, //Aidan
    "59": 11, //Kayla
    "60": 10, //Mushy_and_Shroom
    "61": 8, //Julius
    "62": 57, //Polaris
    "63": 43, //Lara Croft
    "64": 46, //Augustus

    "4000": 1, //Sigurd,
    "4001": 6, //Nova,
    "4002": 12, //Mairi,
    "4003": 17, //Hyperion,

    "4010": 4, //Moloch,
    "4011": 7, //Vulcan,
    "4012": 19, //Ignis,
    "4013": 14, //Araji,

    "4020": 5, //Angus,
    "4021": 20, //Sylva,
    "4022": 10, //Avalon,
    "4023": 15, //Eden,

    "4030": 2, //Brustar,
    "4031": 8, //Mort,
    "4032": 11, //Keros,
    "4033": 18, //Tenebris,

    "4040": 3, //Rigel,
    "4041": 9, //Amon,
    "4042": 16, //Iyari,
    "4043": 13, //Solaris,

    "6000": 6000, //Fenris,
    "6001": 6001, //Oliver,
    "6002": 6002, //Merlin,
    "6003": 6003, //Mara,
    "6004": 6004, //Cain,
    "6005": 6005, //Albus,
    "6006": 6006, //Axel,
    "6007": 6007, //Biscuit,
    "6008": 6008, //Khorus,
}

const aSlots = ['1', '2']
const bSlots = ['3', '4']
const cSlots = ['5', '6']
const dSlots = ['7', '8', '9']
const eSlots = ['10', '11', '12']
const gSlots = ['13', '14', '15']
const hSlots = ['16', '17', '18']
const xSlots = ['19', '20', '21']
const ySlots = ['22', '23', '24']
const zSlots = ['25', '26', '27', '28', '29', '30']

const groupFortList = {
    "1": aSlots,
    "2": aSlots,
    "3": bSlots,
    "4": bSlots,
    "5": cSlots,
    "6": cSlots,
    "7": dSlots,
    "8": dSlots,
    "9": dSlots,
    "10": eSlots,
    "11": eSlots,
    "12": eSlots,
    "13": gSlots,
    "14": gSlots,
    "15": gSlots,
    "16": hSlots,
    "17": hSlots,
    "18": hSlots,
    "19": xSlots,
    "20": xSlots,
    "21": xSlots,
    "22": ySlots,
    "23": ySlots,
    "24": ySlots,
    "25": zSlots,
    "26": zSlots,
    "27": zSlots,
    "28": zSlots,
    "29": zSlots,
    "30": zSlots,
}

export function getGroupFortList(slotId): Array<string> {
    return groupFortList[slotId]
}

const fortNames = {
    '1': 'Академия Магов',
    '2': 'Академия Магов',
    '3': 'Маяк',
    '4': 'Маяк',
    '5': 'Казармы',
    '6': 'Казармы',
    '7': 'Мост',
    '8': 'Мост',
    '9': 'Мост',
    '10': 'Источник Стихий',
    '11': 'Источник Стихий',
    '12': 'Источник Стихий',
    '13': 'Литейная',
    '14': 'Литейная',
    '15': 'Литейная',
    '16': 'Врата Природы',
    '17': 'Врата Природы',
    '18': 'Врата Природы',
    '19': 'Бастион Огня',
    '20': 'Бастион Огня',
    '21': 'Бастион Огня',
    '22': 'Бастион Льда',
    '23': 'Бастион Льда',
    '24': 'Бастион Льда',
    '25': 'Цитадель',
    '26': 'Цитадель',
    '27': 'Цитадель',
    '28': 'Цитадель',
    '29': 'Цитадель',
    '30': 'Цитадель',
}

export function getFortNameBySlotId(slotId): string {
    return fortNames[slotId]
}

interface Item {
    [key: string]: any
    usefulPoints: number
}

export function groupBy(sourceList: Item[], fieldGroupBy: string): Record<string, Item[]> {
    const groupedData: Record<string, Item[]> = {}
    for (const item of sourceList) {
        const slotId: string = item[fieldGroupBy]
        if (slotId in groupedData) {
            groupedData[slotId].push(item)
        } else {
            groupedData[slotId] = [item]
        }
    }
    return groupedData
}

function isFortDestroyed(eventsByFort: Record<string, Item[]>): boolean {
    for (const key in eventsByFort) {
        const val: Item[] = eventsByFort[key]
        for (const val2 of val) {
            if (val2.fPoints > 0) {
                return true
            }
        }
    }
    return false
}

export function createScoreData(groupedTableData: Record<string, Item[]>): { name: string; strikes: number; points: number }[] {
    const result: { name: string; strikes: number; points: number }[] = []

    for (const name in groupedTableData) {
        const items: Item[] = groupedTableData[name]
        const count: number = items.length
        let points: number = 0

        for (const item of items) {
            points += item.points + Math.trunc(item.usefulPoints * 100) / 100
        }

        result.push({name, strikes: count, points})
    }

    return result
}

export function calcPackTotalPower(replay): Array<number> {
    if (replay.length === 0) {
        return [0, 0]
    }

    if (replay.length !== 1) {
        throw new Error('invalid replay length')
    }

    let attackersTotalPower = 0
    for (const key in replay[0]['attackers']) {
        const val = replay[0]['attackers'][key]
        attackersTotalPower += val['power']
    }

    let defendersTotalPower = 0
    for (const key in replay[0]['defenders']) {
        const val = replay[0]['defenders'][key]
        defendersTotalPower += val['power']
    }

    return [attackersTotalPower, defendersTotalPower]
}

export function calcPackTotalPowerNew(replay): Array<number> {
    let attackersTotalPower = 0
    for (const key in replay['attackers']) {
        const val = replay['attackers'][key]
        attackersTotalPower += val['power']
    }

    let defendersTotalPower = 0
    for (const key in replay['defenders']) {
        const val = replay['defenders'][key]
        defendersTotalPower += val['power']
    }

    return [attackersTotalPower, defendersTotalPower]
}

export function getAttackerAndDefenderLists(replay): Object {
    if (replay.length === 0) {
        return {"attackerList": [], "defenderList": []}
    }

    if (replay.length !== 1) {
        throw new Error('invalid replay length')
    }

    let attackerList = []
    for (const key in replay[0]['attackers']) {
        const val = replay[0]['attackers'][key]
        attackerList.push(val)
        // attackerList[key] = val
    }

    let defenderList = []
    for (const key in replay[0]['defenders']) {
        const val = replay[0]['defenders'][key]
        defenderList.push(val)
        // defenderList[key] = val
    }

    // let survivorsAttackers = []
    let survivorsDefenders = []

    //TODO replay[0]['progress'][0]['attackers']['heroes'] - массив ???
    //TODO replay[0]['progress'][0]['defenders']['heroes'] - объект ???

    if (replay[0]['progress'] !== undefined && replay[0]['progress'].length > 0) {
        // Есть выжившие защитники
        if (
            replay[0]['progress'][0] !== undefined &&
            isObject(replay[0]['progress'][0]['defenders']['heroes']) &&
            !isEmpty(replay[0]['progress'][0]['defenders']['heroes'])
        ) {
            survivorsDefenders = replay[0]['progress'][0]['defenders']['heroes']
            defenderList = enrichmentResult(sortByPlaceRank(cloneDeep(defenderList), 1), survivorsDefenders)
        }
    }

    //Сортировка нужна, т.к. при конвертации из json порядок по ключам - id не сохраняется
    attackerList = sortByPlaceRank(attackerList, 1)
    defenderList = sortByPlaceRank(defenderList, -1)

    const result = {"attackerList": attackerList, "defenderList": defenderList}

    //Флаги
    if (replay[0]["effects"] && replay[0]["effects"]["attackersBanner"] && replay[0]["effects"]["attackersBanner"]["id"] > 0) {
        result["attackerBanner"] = replay[0]["effects"]["attackersBanner"]
    }
    if (replay[0]["effects"] && replay[0]["effects"]["defendersBanner"] && replay[0]["effects"]["defendersBanner"]["id"] > 0) {
        result["defenderBanner"] = replay[0]["effects"]["defendersBanner"]
    }

    return result
}

export function getAttackerAndDefenderListsNew(replay): Object {
    //Сортировка нужна, т.к. при конвертации из json порядок по ключам - id не сохраняется
    const attackerList = sortByPlaceRank(replay['attackers'], 1)
    const defenderList = sortByPlaceRank(replay['defenders'], -1)

    return {"attackerList": attackerList, "defenderList": defenderList}
}

function enrichmentResult(participantList: [], result: object): [] {
    Object.keys(result).forEach(function (key) {
        if (participantList[key] !== undefined) {
            participantList[key]['result'] = result[key]
        }
    })

    return participantList
}

function sortByPlaceRank(participantList: [], orderBy: number): [] {
    participantList.sort(function (a, b) {
        if (a['id'] === undefined || !isNumber(a['id'])) {
            throw new Error('Invalid id: ' + a)
        }
        if (b['id'] === undefined || !isNumber(b['id'])) {
            throw new Error('Invalid id: ' + b)
        }

        switch (orderBy) {
            case 1:
                return heroOrderPlace[b['id']] - heroOrderPlace[a['id']]
            case -1:
                return heroOrderPlace[a['id']] - heroOrderPlace[b['id']]
            default:
             throw new Error('Invalid orderBy: ' + orderBy)
        }
    })
    return participantList
}

interface TableData {
    [key: string]: any
    usefulPoints: number
}

export function updateUsefulPoints(tableData: TableData[], usefulPoints: number): TableData[] {
    const groupedTableData: Record<string, TableData[]> = groupBy(tableData, 'slotId')

    const cache: Record<string, boolean> = {}
    const deletedEvents: string[] = []

    for (const slotId in groupedTableData) {
        if (slotId in cache) {
            continue
        }

        const fortSlotList: string[] = getGroupFortList(slotId) //['7','8','9']
        try {
            const eventsByFort: Record<string, TableData[]> = {}
            for (const key of fortSlotList) {
                eventsByFort[key] = groupedTableData[key] ? groupedTableData[key] : []
            }

            const fortDestroyed: boolean = isFortDestroyed(eventsByFort)

            if (!fortDestroyed) {
                deletedEvents.push(...fortSlotList)
            }

            for (let i = 0; i < fortSlotList.length; i++) {
                const kk: string = fortSlotList[i]
                cache[kk] = true
            }
        } catch (error) {
            // Handle errors here
        }
    }

    for (const k of deletedEvents) {
        try {
            delete groupedTableData[k]
        } catch (error) {
            // Handle errors here
        }
    }

    for (const slotId in groupedTableData) {
        const items: TableData[] = groupedTableData[slotId]
        const count: number = items.length
        for (const item of items) {
            item.usefulPoints += usefulPoints / count
        }
    }

    return tableData
}

export function convertColorToName(color) {
    switch (color) {
        case 1:
            return 'white'
        case 2:
            return 'green'
        case 3:
            return 'green_1'
        case 4:
            return 'blue'
        case 5:
            return 'blue_1'
        case 6:
            return 'blue_2'
        case 7:
            return 'violet'
        case 8:
            return 'violet_1'
        case 9:
            return 'violet_2'
        case 10:
            return 'violet_3'
        case 11:
            return 'gold'
        case 12:
            return 'gold_1'
        case 13:
            return 'gold_2'
        case 14:
            return 'gold_3'
        case 15:
            return 'gold_4'
        case 16:
            return 'red'
        case 17:
            return 'red_1'
        case 18:
            return 'red_2'
        default:
            return ''
    }
}

export function convertColorToShortName(color) {
    switch (color) {
        case 1:
            return 'white'
        case 2:
        case 3:
            return 'green'
        case 4:
        case 5:
        case 6:
            return 'blue'
        case 7:
        case 8:
        case 9:
        case 10:
            return 'violet'
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
            return 'gold'
        case 16:
        case 17:
        case 18:
            return 'red'
        default:
            return ''
    }
}

export function calcPercent(a, b) {
    return (b / a) * 100
}

export function extractClanTitle(userInfo) {
    return userInfo['clanTitle'] && userInfo['clanTitle'] ? userInfo['clanTitle'] : undefined
}

export function formatTimeAgo(lastLoginTime) {
    const secondsAgo = Math.floor(Date.now() / 1000) - lastLoginTime
    const days = Math.floor(secondsAgo / (3600 * 24))
    const hours = Math.floor((secondsAgo % (3600 * 24)) / 3600)
    const minutes = Math.floor((secondsAgo % 3600) / 60)
    const seconds = secondsAgo % 60

    return {days: days, hours: hours, minutes: minutes, seconds: seconds}
}



const colorByIndex = {
    0:  '#d41b16', //'красный',
    1:  '#478ced', //'голубой',
    2:  '#4a861f', //'зеленый',
    3:  '#c7af1d', //'желтый',
    // 3:  '#cbb31e', //'желтый',
    5:  '#d87226', //'оранжевый',
    6:  '#fa9fe0', //'светло фиолетовый',
    7:  '#2e2e2e', //'черный',
    8:  '#1d9386', //'бирюзовый',
    9:  '#51dff5', //'голубой',
    // 10:  '#48317b', //'синий',
    10:  '#8a5eee', //'фиолетовый',
    11:  '#ca60f3', //'фиолетовый',
    13:  '#f31d57', //'розовый',
    14:  '#9f500e', //'коричневый',
    16:  '#1f44b3', //'синий',
    18:  '#97f085', //'светло зеленый',
    19:  '#d4d4d4', //'светло серый',
}

export function getColorById(colorId) {
    return colorByIndex[colorId]
}

const shapesMap = {
    /*100: { 0: '<circle cx="53" cy="67" r="40" fill="#ffffff" />', 1: '<circle cx="53" cy="67" r="40" fill="#ffffff" />' },*/
    0: {
        0:  '<rect x="0" y="0" width="51" height="142" fill="#ffffff" />\n' +
            '<rect x="51" y="0" width="51" height="142" fill="#000000" />',
        1:  '<rect x="0" y="0" width="51" height="142" fill="#000000" />\n' +
            '<rect x="51" y="0" width="51" height="142" fill="#ffffff" />'
    },
    /*43: { 0: '<circle cx="53" cy="67" r="40" fill="#ffffff" />', 1: '<circle cx="53" cy="67" r="40" fill="#ffffff" />' },*/
    1: {
        0:  '<rect x="0" y="0" width="51" height="71" fill="#ffffff" />\n' +
            '<rect x="51" y="71" width="51" height="71" fill="#ffffff" />\n' +
            '<rect x="0" y="71" width="51" height="71" fill="#000000" />\n' +
            '<rect x="51" y="0" width="51" height="71" fill="#000000" />',
        1:  '<rect x="0" y="0" width="51" height="71" fill="#000000" />\n' +
            '<rect x="51" y="71" width="51" height="71" fill="#000000" />\n' +
            '<rect x="0" y="71" width="51" height="71" fill="#ffffff" />\n' +
            '<rect x="51" y="0" width="51" height="71" fill="#ffffff" />'
    },
    2: {
        0:  '<rect x="0" y="0" width="102" height="71" fill="#ffffff" />\n' +
            '<rect x="0" y="71" width="102" height="71" fill="#000000" />\n',
        1:  '<rect x="0" y="0" width="102" height="71" fill="#000000" />\n' +
            '<rect x="0" y="71" width="102" height="71" fill="#ffffff" />'
    },
    3: {
        0: generate3Polygons([0, 0, 102, 142],[0, 112, 102, 0], [0, 142, 102, 30],'#ffffff', "#000000"),
        1: generate3Polygons([0, 0, 102, 142],[0, 112, 102, 0], [0, 142, 102, 30],"#000000", '#ffffff'),
    },
    4: {
        0: generate3Polygons([0, 0, 102, 142],[0, 0, 102, 112], [0, 30, 102, 142],'#ffffff', "#000000"),
        1: generate3Polygons([0, 0, 102, 142],[0, 0, 102, 112], [0, 30, 102, 142],"#000000", '#ffffff'),
    },
    5: {
        0:  '<rect x="0" y="0" width="102" height="142" fill="#ffffff" />\n' +
            '<line x1="0" y1="0" x2="102" y2="116" stroke="#000000" stroke-width="30" />\n' +
            '<line x1="0" y1="116" x2="102" y2="0" stroke="#000000" stroke-width="30" />',
        1:  '<rect x="0" y="0" width="102" height="142" fill="#000000" />\n' +
            '<line x1="0" y1="0" x2="102" y2="116" stroke="#ffffff" stroke-width="30" />\n' +
            '<line x1="0" y1="116" x2="102" y2="0" stroke="#ffffff" stroke-width="30" />'
    },
    6: {
        0:  '<rect x="0" y="0" width="102" height="142" fill="#ffffff" />\n' +
            '<polygon points="102,0 102,142 0,142 0,116" fill="#000000" />',
        1:  '<rect x="0" y="0" width="102" height="142" fill="#000000" />\n' +
            '<polygon points="102,0 102,142 0,142 0,116" fill="#ffffff" />'
    },
    8: {
        0:  '<rect x="0" y="0" width="102" height="142" fill="#ffffff" /><rect x="31" y="0" width="40" height="142" fill="#000000" />',
        1:  '<rect x="0" y="0" width="102" height="142" fill="#000000" /><rect x="31" y="0" width="40" height="142" fill="#ffffff" />'
    },
    10: {
        0:  '<rect x="0" y="0" width="102" height="142" fill="#ffffff" /><rect x="38" y="0" width="30" height="142" fill="#000000" /><rect x="0" y="48" width="102" height="30" fill="#000000" />',
        1:  '<rect x="0" y="0" width="102" height="142" fill="#000000" /><rect x="38" y="0" width="30" height="142" fill="#ffffff" /><rect x="0" y="48" width="102" height="30" fill="#ffffff" />'
    },
    12:{
        0: generateZebraPattern(102, 142, 9, "#000000", '#ffffff'),
        1: generateZebraPattern(102, 142, 9, '#ffffff', "#000000")
    },
    13: {
        0: generateCheckerboardPattern(102, 142, 8, '#ffffff', "#000000"),
        1: generateCheckerboardPattern(102, 142, 8, "#000000", '#ffffff')
    },
    14: { 0: '<rect x="0" y="0" width="102" height="142" fill="#ffffff" /><circle cx="53" cy="67" r="40" fill="#000000" />', 1: '<rect x="0" y="0" width="102" height="142" fill="#000000" /><circle cx="53" cy="67" r="40" fill="#ffffff" />' },
    16: {
        1:  '<polygon points="0,116 51,31 102,116" fill="#ffffff" />',
        0:  '<polygon points="0,116 51,31 102,116" fill="#000000" />'
    }
}

function generate3Polygons(
    rect: [number, number, number, number], // [x1, y1, x2, y2] — координаты углов прямоугольника
    line1: [number, number, number, number], // [x1, y1, x2, y2] — координаты первой прямой
    line2: [number, number, number, number],  // [x1, y1, x2, y2] — координаты второй прямой
    fillColor1: string,
    fillColor2: string
): string {
    const [rx1, ry1, rx2, ry2] = rect;
    const [l1x1, l1y1, l1x2, l1y2] = line1;
    const [l2x1, l2y1, l2x2, l2y2] = line2;

    // Первая фигура - треугольник
    const polygon1 = `<polygon points="${l1x1},${l1y1} ${l1x2},${l1y2} ${rx1},${ry1}" fill="${fillColor1}"/>`;

    // Вторая фигура - четырехугольник
    const polygon2 = `<polygon points="${l2x1},${l2y1} ${l2x2},${l2y2} ${rx2},${ry2} ${rx1},${ry2}" fill="${fillColor1}"/>`;

    // Третья фигура - пятиугольник
    const polygon3 = `<polygon points="${l1x1},${l1y1} ${l1x2},${l1y2} ${rx2},${ry1} ${l2x2},${l2y2} ${l2x1},${l2y1}" fill="${fillColor2}"/>`;

    return polygon1 + '\n' + polygon2 + '\n' + polygon3 + '\n'
}

function generateZebraPattern(
    width: number,
    height: number,
    stripeHeight: number,
    color1: string,
    color2: string
): string {
    let pattern = ''
    const stripes = Math.ceil(height / stripeHeight)

    for (let i = 0; i < stripes; i++) {
        const fillColor = i % 2 === 0 ? color1 : color2
        const rectHeight = Math.min(stripeHeight, height - i * stripeHeight)
        pattern += `<rect x="0" y="${i * stripeHeight}" width="${width}" height="${rectHeight}" fill="${fillColor}" />\n`
    }

    return pattern
}

function generateCheckerboardPattern(
    width: number,
    height: number,
    squareSize: number,
    color1: string,
    color2: string
): string {
    let pattern = ''
    const rows = Math.ceil(height / squareSize)
    const cols = Math.ceil(width / squareSize)

    for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
            const fillColor = (x + y) % 2 === 0 ? color1 : color2
            const rectWidth = Math.min(squareSize, width - x * squareSize)
            const rectHeight = Math.min(squareSize, height - y * squareSize)
            pattern += `<rect x="${x * squareSize}" y="${y * squareSize}" width="${rectWidth}" height="${rectHeight}" fill="${fillColor}" />\n`
        }
    }

    return pattern
}

const coordinatesMap = {
    181: { x: -446, y: -197 },
    2: { x: -564, y: -126 },
    4: { x: -393, y: -380 },
    5: { x: -804, y: -126 },
    8: { x: -769, y: -1 },
    10: { x: -444, y: -131 },
    11: { x: -447, y: -1 },
    17: { x: -340, y: -452 },
    18: { x: -626, y: -126 },
    20: { x: -624, y: -238 },
    22: { x: -446, y: -392 },
    23: { x: -690, y: -60 },
    24: { x: -570, y: -65 },
    26: { x: -340, y: -246 },
    27: { x: -686, y: -126 },
    28: { x: -564, y: -380 },
    29: { x: -746, y: -120 },
    31: { x: -517, y: -1 },
    32: { x: -115, y: -448 },
    37: { x: -176, y: -448 },
    39: { x: -509, y: -65 },
    43: { x: -562, y: -251 },
    44: { x: -829, y: -1 },
    46: { x: -231, y: -442 },
    47: { x: -340, y: -110 },
    48: { x: -447, y: -63 },
}

export function createFlagShapePattern(shapeId) {
    const shapes = shapesMap[shapeId] || shapesMap[0]
    return `
        <mask id="mask-flag-shape-${shapeId}" x="0" y="0" width="102" height="142">
          `+shapes[0]+`
        </mask>
        <mask id="inverted-mask-flag-shape-${shapeId}" x="0" y="0" width="102" height="142" fill-rule="evenodd">
          `+shapes[1]+`
        </mask>`
}

export function createIconShapePattern(shapeId) {
    const coordinates = coordinatesMap[shapeId] || { x: 0, y: 0 }

    return `
    <pattern id="icon-shape-${shapeId}" width="100%" height="100%">
        <image href="img/avatar/clanIcons.png" x="${coordinates.x}" y="${coordinates.y}" width="894" height="512" />
    </pattern>`
}
