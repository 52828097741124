<template>
  <div class="d-block">
    <div class="titan-icon">
      <img :src="titanIconBorder" class="border" />
      <c-titan-icon :id="titanId" width="60" height="60" class="icon"/>
      <c-titan-level :level="titanLevel" :color="titanIconBackgroundColor" class="level"/>
      <c-titan-stars :stars="titanStars" class="stars"/>
    </div>
    <c-item-xp v-if="titanInfo" :xp="titanInfo.power"/>
    <c-progress-bar v-if="titanInfo.result" :progressPercent="calcPercent(titanInfo.hp, titanInfo.result.hp)" bgColor="emerald"/>
    <c-progress-bar v-if="titanInfo.result" :progressPercent="calcPercent(1000, titanInfo.result.energy)" bgColor="amber"/>
  </div>
</template>

<script>
import HeroIcon from "@src/components/hero/TitanIcon.vue";
import HeroStars from "@src/components/hero/HeroStars.vue";
import HeroLevel from "@src/components/hero/HeroLevel.vue";
import ItemXp from "@src/components/hero/ItemXp.vue";
import ProgressBar from "@src/components/ProgressBar.vue"

export default {
  components: {
    'c-titan-icon': HeroIcon,
    'c-titan-stars': HeroStars,
    'c-titan-level': HeroLevel,
    'c-item-xp': ItemXp,
    'c-progress-bar': ProgressBar,
  }
}
</script>

<script setup>
import {convertColorToName, convertColorToShortName, calcPercent} from "@src/components/common.ts"
import {computed} from 'vue'

const props = defineProps({
  titanInfo: {
    type: Object,
    required: true,
  },
})

const titanId = computed(() => {
  return String(props.titanInfo.id).padStart(4, "0")
})

const titanIconBackgroundColor = computed(() => {
  return 'white'
})

const titanIconBorder = computed(() => {
  return '/img/titan/' + 'border_titan.png'
})

const titanLevel = computed(() => props.titanInfo.level)

const titanStars = computed(() => props.titanInfo.star)

</script>

<style scoped>
.titan-icon {
  position: relative;
  width: 60px;
  height: 60px;
}

.patronage {
  position: absolute;
  top: -8px;
  left: -5px;
  width: 25px;
  height: 25px;
  z-index: 90;
}

.level {
  position: absolute;
  top: 1px;
  left: 16px;
}

.stars {
  position: absolute;
  bottom: -1px;
  left: 0px;
}

.border, .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-width: 0px;
}

.icon {
  padding-top: 4px;
  padding-left: 4px;
  /*border-style: none;*/
  width: 56px;
  height: 56px;
}

.level {
  position: absolute;
  top: 1px;
  left: 16px;
}

.stars {
  position: absolute;
  bottom: -1px;
  left: 0px;
}
</style>
