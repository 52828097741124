import { getFortNameBySlotId, calcPackTotalPower, updateUsefulPoints,
    getAttackerAndDefenderLists, extractClanTitle, createScoreData, groupBy } from './common.ts'

export function extractData(eventData) {
    if (
        eventData['attack'] === undefined ||
        eventData['defence'] === undefined ||
        eventData['users'] === undefined ||
        eventData['replays'] === undefined
    ) {
        return undefined
    }

    return {
        'attackList': eventData['attack'],
        'defenceList': eventData['defence'],
        'users': eventData['users'],
        'replays': eventData['replays']
    }
}

export function createClanWarData(eventData) {
    const sourceData = extractData(eventData)
    if (sourceData === undefined) {
        return undefined
    }

    let attackGuild = undefined
    let defGuild = undefined
    let eventDate1 = ''

    let attackTotalPoints = 0
    let defTotalPoints = 0

    let tableData1 = []
    for (const key in sourceData['attackList']) {
        const val = sourceData['attackList'][key]

        const attackerId = val['attackerId']
        let attackerName = ''
        if (sourceData['users'][attackerId] && sourceData['users'][attackerId]['name']) {
            attackerName = sourceData['users'][attackerId]['name']
        } else {
            attackerName = 'none'
        }

        const defenderId = val['defenderId']
        let defenderName = ''
        if (sourceData['users'][defenderId] && sourceData['users'][defenderId]['name']) {
            defenderName = sourceData['users'][defenderId]['name']
        } else {
            defenderName = 'none'
        }

        if (eventDate1 === '') {
            eventDate1 = val['time']
        }
        if (attackGuild === undefined) {
            // attackGuild = sourceData['users'][attackerId]['clanTitle']
            if (sourceData['users'][attackerId] !== undefined) {
                const attackGuildName = extractClanTitle(sourceData['users'][attackerId])
                if (attackGuildName !== undefined) {
                    attackGuild = {name: attackGuildName, clanIcon: sourceData['users'][attackerId]['clanIcon']}
                }
            }

        }
        if (defGuild === undefined) {
            if (sourceData['users'][defenderId] !== undefined) {
                const defGuildName = extractClanTitle(sourceData['users'][defenderId])
                if (defGuildName !== undefined) {
                    defGuild = {name: defGuildName, clanIcon: sourceData['users'][defenderId]['clanIcon']}
                }
            }
        }

        const res = val['win'] === true ? 'win' : 'loss'
        const points = val['slotPoints']

        const rowData = {
            'attacker' : attackerName, 'attackerId' : attackerId,
            'defender' : defenderName, 'defenderId' : defenderId,
            'res' : res, 'points': points
        }
        const fPoints = val['fortificationPoints']
        attackTotalPoints += points + fPoints

        rowData['slotId'] = val['slotId']
        rowData['fPoints'] = val['fortificationPoints']
        rowData['usefulPoints'] = 0

        const replaysList = sourceData.replays.filter(x => x['id'] === val['replayId']);
        const packTotalPower = calcPackTotalPower(replaysList)
        const battleParticipantList = getAttackerAndDefenderLists(replaysList)

        let cssStyle = ''
        if (res === 'win') {
            if (points === 20) {
                cssStyle = 'green'
            } else {
                cssStyle = 'blue'
            }
        } else {
            cssStyle = 'red'
        }

        rowData['cssStyle'] = cssStyle
        rowData['attackerPower'] = packTotalPower[0]
        rowData['attackers'] = battleParticipantList['attackerList']
        if (battleParticipantList['attackerBanner']) {
            rowData['attackerBanner'] = battleParticipantList['attackerBanner']
        }
        rowData['defenderPower'] = packTotalPower[1]
        rowData['defenders'] = battleParticipantList['defenderList']
        if (battleParticipantList['defenderBanner']) {
            rowData['defenderBanner'] = battleParticipantList['defenderBanner']
        }
        rowData['fortName'] = getFortNameBySlotId(val['slotId'])

        tableData1.push(rowData)
    }

    //Пересчитываем с учетом полностью захваченных гарнизонов
    tableData1 = updateUsefulPoints(tableData1, 20)

    const attackScoreData = createScoreData(groupBy(tableData1, 'attacker'))
    attackScoreData.sort((a, b) => {
        return b.points - a.points;
    })


    let tableData2 = []
    for (const key in sourceData['defenceList']) {
        const val = sourceData['defenceList'][key]

        const attackerId = val['attackerId']
        let attackerName = ''
        if (sourceData['users'][attackerId] && sourceData['users'][attackerId]['name']) {
            attackerName = sourceData['users'][attackerId]['name']
        } else {
            attackerName = 'none'
        }

        const defenderId = val['defenderId']
        let defenderName = ''
        if (sourceData['users'][defenderId] && sourceData['users'][defenderId]['name']) {
            defenderName = sourceData['users'][defenderId]['name']
        } else {
            defenderName = 'none'
        }

        const res = val['win'] === true ? 'win' : 'loss'
        const points = val['slotPoints']

        const rowData = {
            'attacker' : attackerName, 'attackerId' : attackerId,
            'defender' : defenderName, 'defenderId' : defenderId,
            'res' : res, 'points': points
        }

        const fPoints = val['fortificationPoints']
        defTotalPoints += points + fPoints

        rowData['slotId'] = val['slotId']
        rowData['fPoints'] = val['fortificationPoints']
        rowData['usefulPoints'] = 0

        const replaysList = sourceData.replays.filter(x => x['id'] === val['replayId']);
        const packTotalPower = calcPackTotalPower(replaysList)
        const battleParticipantList = getAttackerAndDefenderLists(replaysList)

        let cssStyle = ''
        // In defense, inverse
        if (res === 'win') {
            if (points === 20) {
                cssStyle = 'red'
            } else {
                cssStyle = 'blue'
            }
        } else {
            cssStyle = 'green'
        }

        rowData['cssStyle'] = cssStyle
        rowData['attackerPower'] = packTotalPower[0]
        rowData['attackers'] = battleParticipantList['attackerList']
        rowData['defenderPower'] = packTotalPower[1]
        rowData['defenders'] = battleParticipantList['defenderList']
        rowData['fortName'] = getFortNameBySlotId(val['slotId'])

        tableData2.push(rowData)
    }

    // Пересчитываем с учетом полностью захваченных гарнизонов
    tableData2 = updateUsefulPoints(tableData2, 20)

    const opponentAttackScoreData = createScoreData(groupBy(tableData2, 'attacker'))
    opponentAttackScoreData.sort((a, b) => {
        return b.points - a.points;
    })


    const formatDate = new Date(eventDate1 * 1000).toLocaleDateString("ru-RU", {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).toString()

    return {
        "date": formatDate, "attackGuild": attackGuild, "defGuild": defGuild,
        "attackTotalPoints": attackTotalPoints, "defTotalPoints": defTotalPoints,
        "tableData1": tableData1, "tableData2": tableData2,
        "attackScoreData": attackScoreData, "opponentAttackScoreData": opponentAttackScoreData,
        "clanDefenderId": eventData.clanDefenderId,
    }
}