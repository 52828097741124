<template>
  <div v-if="userData" class="d-block">
    <c-avatar :avatarId="avatarId" :frameId="frameId" :userName="userName" :teamLevel="teamLevel" />
    <c-clan-icon v-if="clanTitle" :clanIcon="clanIcon" :clanLeader="clanLeader" />
    <div><b>{{userName}}</b></div>
    <div>{{lastLoginTime}}</div>
  </div>
</template>

<script>
import Avatar from "@src/components/Avatar.vue";
import ClanIcon from "@src/components/ClanIcon.vue";
import {formatTimeAgo} from "./common";

export default {
  name: `c-user`,
  components: {
    'c-avatar': Avatar,
    'c-clan-icon': ClanIcon,
  },
  props: {
    userData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    avatarId() {
      return this.userData.avatarId
    },
    frameId() {
      return this.userData.frameId
    },
    userName() {
      return this.userData.name
    },
    userId() {
      return this.userData.id
    },
    teamLevel() {
      return this.userData.level
    },
    clanTitle() {
      return this.userData.clanTitle
    },
    clanIcon() {
      return this.userData.clanIcon
    },
    lastLoginTime() {
      const timeAgo = formatTimeAgo(this.userData.lastLoginTime)

      let timeAgoString = '';
      if (timeAgo.days > 0) {
        timeAgoString += `${timeAgo.days} дней `;
      }
      if (timeAgo.hours > 0) {
        timeAgoString += `${timeAgo.hours} часов `;
      }
      if (timeAgo.minutes > 0) {
        timeAgoString += `${timeAgo.minutes} минут `;
      }
      timeAgoString += `${timeAgo.seconds} секунд назад`;

      return timeAgoString;
    },
    clanLeader() {
      return this.userData.clanId === '182770'
    },
    // teamIconBackgroundColor() {
    //   // return this.convertColorToShortName(this.heroInfo.color)
    //   return 'blue'
    // },
  },
  methods: {
    // convertColorToName,
    // convertColorToShortName,
    // calcPercent
  },
  mounted() {
  },
}
</script>

<style scoped>

.d-block {
  display: inline-block;
}

/*.image-frame {*/
/*  margin-left: 2px;*/
/*  margin-top: 2px;*/
/*  overflow: hidden;*/
/*  text-align: center;*/
/*  border-radius: 10px;*/
/*  display: block;*/
/*}*/

/*.avatar-icon {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  flex-direction: column;*/
/*  position: relative;*/

/*  width: 80px;*/
/*  height: 80px;*/
/*}*/

/*img {*/
/*  vertical-align: middle;*/
/*  border-style: none;*/
/*}*/

/*img.frame {*/
/*  position: absolute;*/
/*  top: -7px;*/
/*  left: -7px;*/
/*  width: 120%;*/
/*  height: 120%;*/
/*  max-width: none;*/

/*  z-index: 50;*/
/*}*/

/*img.image {*/
/*  position: absolute;*/
/*  !*top: 0;*!*/
/*  !*left: 0;*!*/
/*  !*width: 90%;*!*/
/*  !*height: 90%;*!*/
/*  !*margin: 5px;*!*/
/*  border-radius: 45%;*/
/*}*/

/*.portal-header > span {*/
/*  height: 58px;*/
/*  font-size: 26px;*/
/*  padding: 5px 0;*/
/*  background: url(img/header-body.png) no-repeat;*/
/*  color: white;*/
/*}*/
/*.portal-header::before, .portal-header::after {*/
/*  display: block;*/
/*  content: '';*/
/*  height: 58px;*/
/*  width: 101px;*/
/*  background: url(img/header-decor.png) no-repeat;*/
/*}*/
/*!*.portal-header::after {*!*/
/*!*  display: block;*!*/
/*!*  content: '';*!*/
/*!*  height: 58px;*!*/
/*!*  width: 101px;*!*/
/*!*  background-image: url(img/header-decor.png);*!*/
/*!*}*!*/

</style>
