import { getToken as getTokenFromModule } from './auth';

export function isAuthenticated(): boolean
{
    const token = getTokenFromModule()
    if (token) {
        if (token.split('.').length === 3) {
            try {
                const base64Url = token.split('.')[1]
                const base64 = base64Url.replace('-', '+').replace('_', '/')
                const exp = JSON.parse(window.atob(base64)).exp
                if (typeof exp === 'number') {
                    return Math.round(new Date().getTime() / 1000) < exp
                }
            } catch (e) {
                // return true
            }
        }
        // return true
    }
    return false
}

export function getTokenPayload(): Record<string, any> | undefined
{
    const token = getTokenFromModule()
    if (token) {
        if (token.split('.').length === 3) {
            try {
                const base64Url = token.split('.')[1]
                const base64 = base64Url.replace('-', '+').replace('_', '/')

                const payload = JSON.parse(atob(base64))
                if (payload !== undefined) {
                    return payload
                }
            } catch (e) {
                return undefined
            }
        }
        return undefined
    }
    return undefined
}

export function getToken(): string | undefined
{
    return localStorage.getItem('token');
}

// export async function login(userId: string, password: string): Promise<string>
// {
//     try {
//         // const response = await api.login(/* передайте данные аутентификации */);
//         // this.isButtonDisabled = true
//         const payload = {"userId": userId, "password": password}
//         await fetch(import.meta.env.VITE_STATS_API_URL + 'auth', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(payload)
//         }).then((response) => {
//             // this.isButtonDisabled = false
//             // if (response.status !== undefined) {
//                 if (response.status === 200 && response.body !== undefined) {
//                     return response.json()
//                 } else {
//                     throw new Error('invalid response: ' + response)
//                 }
//             // } else {
//             //     throw new Error('invalid response: ' + response)
//             // }
//         }).then((jsonResponse) => {
//             const token = jsonResponse.token;
//             //
//             // this.$store.commit('setToken', token);
//             // localStorage.setItem('token', token);
//
//             return token
//
//         }).catch((error) => {
//             // this.isButtonDisabled = false
//             console.log(error)
//         })
//     } catch (error) {
//         console.error('Ошибка при аутентификации:', error);
//     }
// }

export async function login(userId: string, password: string): Promise<string> {
    try {
        const payload = { "userId": userId, "password": password };
        const response = await fetch(import.meta.env.VITE_STATS_API_URL + 'auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (response.status === 200 /*&& response.body !== undefined*/) {
            const jsonResponse = await response.json();
            const token = jsonResponse.token;
            return token;
        } else {
            throw new Error('Invalid response: ' + response.status);
        }
    } catch (error) {
        console.error('Ошибка при аутентификации:', error);
        throw error;
    }
}