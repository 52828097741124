import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';
import { authModule } from './modules/auth';
import { appModule } from './modules/app';
import { svgMapModule } from './modules/map';

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
    reducer: (state) => ({
        auth: state.auth, // Сохраняем только модуль auth в localStorage
    }),
})

const store = createStore({
    modules: {
        auth: authModule,
        app: appModule,
        map: svgMapModule,
    },
    plugins: [vuexLocalStorage.plugin],
})

export default store;