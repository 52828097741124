
export const authModule = {
    namespaced: true, // Используем пространственные имена
    state: () => ({
        token: null,
        isAuthenticated: false,
    }),
    getters: {
        isAuthenticated: (state) => () => {
            return state.isAuthenticated
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        clearToken(state) {
            state.token = null;
        },
    },
    actions: {
        // Действие для обновления токена
        async refreshToken({ commit }) {
            try {
                // Ваш код для отправки запроса на обновление токена
                // ...

                // После успешного обновления токена сохраните новый токен в состоянии
                const newToken = 'новый_токен'; // Замените на вашу логику обновления токена
                commit('setToken', newToken);
            } catch (error) {
                // Обработка ошибок при обновлении токена
                console.error('Ошибка при обновлении токена:', error);
                commit('clearToken'); // Очистите токен, если обновление не удалось
            }
        },
    },
}
