<template>
  <div class="lvl">
    <img :src="heroLevelBackground"/>
    <span>{{level}}</span>
  </div>
</template>

<script>

export default {
  name: `c-hero-level`,
  props: {
    level: {
      type: [String, Number],
      require: true,
    },
    color: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    heroLevelBackground() {
      return '/img/level_' + this.color + '.png'
    },
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>

.lvl {
  flex: 1;
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: flex-start; /* Размещение в верхней части */
  width: 26px;
  margin: 0 auto;

  z-index: 80;
  position: relative;
}

.lvl img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.lvl span {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  font-weight: bold;
  font-family: Arial,sans-serif;
  font-size: 11px;
  text-align: center;

  z-index: 81;
}

</style>
