<template>
  <svg style="display: none;">
    <defs>
      <HeroIconDefs />
      <TitanIconDefs />
      <BannerIconDefs />
    </defs>
  </svg>
</template>

<script>
import HeroIconDefs from "@src/components/hero/HeroIconDefs.vue";
import TitanIconDefs from "@src/components/hero/TitanIconDefs.vue";
import BannerIconDefs from "@src/components/hero/BannerIconDefs.vue";

export default {
  name: "InitDefs",
  components: {
    HeroIconDefs,
    TitanIconDefs,
    BannerIconDefs,
  },
};
</script>