<template>
  <div @click="toggleDropdown">
    <input
        v-model="searchTerm"
        class="w-full p-2 border border-gray-300 rounded"
        placeholder="select ..."
    />
    <div class="relative mt-2">
      <div
          v-if="isDropdownVisible"
          class="dropdown"
      >
        <div
            v-for="option in filteredOptions"
            :key="option.value"
            @click="selectOption(option)"
            class="p-2 cursor-pointer hover:bg-gray-100"
        >
<!--          {{ option.title }}-->
          <span v-html="option.item"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: `c-dropdown`,
  // components: {
  // },
  props: {
    optionList: {
      type: Array,
      require: true,
    },
    filterMode: {
      type: String,
      default: 'first', // 'first' or 'last'
    },
    filterSize: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      searchTerm: "",
      isDropdownVisible: false,
    };
  },
  computed: {
    filteredOptions() {
      if (!this.searchTerm.toLowerCase()) {
        return this.filterMode === 'last'
            ? this.optionList.slice(-this.filterSize)
            : this.optionList.slice(0, this.filterSize)
      }

      return this.optionList.filter((option) =>
          option.item.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  methods: {
    selectOption(option) {
      this.searchTerm = option.item.replace(/<[^>]+>/g, '')
      this.isDropdownVisible = false
      this.$emit("option-selected", option)
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
  },
  watch: {
    searchTerm() {
      this.isDropdownVisible = true;
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.input {
  @apply w-full p-2 border border-gray-300 rounded;
}

.dropdown {
  @apply absolute z-10 w-full bg-white border border-gray-300 rounded mt-1 shadow-md;
  z-index: 500;
}

.dropdown-item {
  @apply p-2 cursor-pointer hover:bg-gray-100;
}
</style>
