import { getToken } from "@src/components/auth/auth.ts"
import {isArray, isObject, cloneDeep} from "lodash";
import {createCDData} from "../../components/ClanDomination";

export const svgMapModule = {
    namespaced: true,
    state: () => ({
        svgClanDominationMap: null,
        // eventMoveClanDominationMap: null,
        // eventBattleClanDominationMap: null
        userPositions: {},
        townPositions: {},
        chestPositions: {},
        visiblePositions: [],
        users: {},
        cdBattleEvents: [],
    }),
    getters: {
        getSvgClanDominationMap: (state) => {
            return state.svgClanDominationMap
        },
        getUserPositions: (state) => {
            return state.userPositions
        },
        getTownPositions: (state) => {
            return state.townPositions
        },
        getChestPositions: (state) => {
            return state.chestPositions
        },
        getVisiblePositions: (state) => {
            return state.visiblePositions
        },
        getUserById: (state) => (key) => {
            return state.users[key] || null
        },
        getUserByIds: (state) => (ids) => {
            return ids.map(id => state.users[id] || null)
        },
        getAllUsers: (state) => {
            return state.users
        },
        getMissingUserIds: (state) => (ids) => {
            return ids.filter(id => !state.users[id])
        },
        getCDBattleEventsByUserId: (state) => (userId) => {
            // const result = {}
            // Object.keys(state.cdBattleEvents).forEach((startTime) => {
            //     if (
            //         state.cdBattleEvents[startTime] &&
            //         (state.cdBattleEvents[startTime].userId === userId || state.cdBattleEvents[startTime].typeId === userId)
            //     ) {
            //         result[startTime] = cloneDeep(state.cdBattleEvents[startTime])
            //     }
            // })
            const result = []
            state.cdBattleEvents.forEach((item) => {
                if (item && (item.attackerId === userId || item.defenderId === userId)) {
                    result.push(cloneDeep(item))
                }
            })

            return result
        },
    },
    mutations: {
        setSvgClanDominationMap(state, svgMap) {
            state.svgClanDominationMap = svgMap
        },
        setUserPositions(state, data) {
            state.userPositions = data
        },
        setTownPositions(state, data) {
            state.townPositions = data
        },
        setChestPositions(state, data) {
            state.chestPositions = data
        },
        setVisiblePositions(state, data) {
            state.visiblePositions = data
        },
        setUserById(state, { id, userData }) {
            state.users = {
                ...state.users,
                [id]: userData
            }
        },
        setCDBattleEventById(state, { startTime, cdBattleEvent }) {
            state.cdBattleEvents = {
                ...state.cdBattleEvents,
                [startTime]: cdBattleEvent
            }
        },
        // setCDBattleEvent(state, { cdBattleEvent }) {
        //     state.cdBattleEvents = {
        //         ...state.cdBattleEvents,
        //         [startTime]: cdBattleEvent
        //     }
        // },
        setCDBattleEvent(state, cdBattleEvent) {
            state.cdBattleEvents = cdBattleEvent
        },
    },
    actions: {
        generateMap({ commit, state }, svgMap) {
            commit('setSvgClanDominationMap', svgMap)
        },
        saveMoveEvent({ commit, state }, moveEvent) {
            const userPositions = getUserPositions(moveEvent)
            if (userPositions) {
                commit('setUserPositions', userPositions)
            }
            const townPositions = getTownPositions(moveEvent)
            if (townPositions) {
                commit('setTownPositions', townPositions)
            }
            const chestPositions = getChestPositions(moveEvent)
            if (chestPositions) {
                commit('setChestPositions', chestPositions)
            }
            const visiblePositions = getVisiblePositions(moveEvent)
            if (visiblePositions) {
                commit('setVisiblePositions', visiblePositions)
            }
            if (moveEvent.user && moveEvent.user.id) {
                const id = (moveEvent.user.id).toString()
                const userData = moveEvent.user
                commit('setUserById', {id, userData})
            }
        },
        async fetchUsers({ commit, state }, userIds) {
            try {
                const response = await fetch(getUrlApi() + 'users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    },
                    body: JSON.stringify({userIds: userIds})
                })

                if (response.ok && response.status === 200)  {
                    const usersData = extractUsersData(await response.json())
                    Object.keys(usersData).forEach((id) => {
                        const userData = usersData[id]
                        commit('setUserById', {id, userData})
                    })
                } else {
                    console.error(await response.json())
                }
                // return response.data
            } catch (error) {
                console.error(error.message)
            }
        },
        async fetchCDBattleEvents({ commit, state }, payload) {
            try {
                const response = await fetch(getUrlApi() + 'cd/dateFrom/' + payload.dateFrom, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    },
                })

                if (response.ok && response.status === 200)  {
                    const responseBody = await response.json()

                    if (responseBody.cdEvents) {
                        // const cdBattleEventsData = extractCDBattleEventsData(responseBody.cdEvents)
                        // const cdBattleEventsData = extractData(responseBody.cdEvents)
                        const cdBattleEventsData = createCDData(responseBody)

                        if (isArray(cdBattleEventsData) && cdBattleEventsData.length > 0) {
                            // cdBattleEventsData.forEach((cdBattleEvent) => {
                            //     commit('setCDBattleEvent', { cdBattleEvent })
                            // })
                            commit('setCDBattleEvent', cdBattleEventsData)
                        }

                        // Object.keys(cdBattleEventsData).forEach((startTime) => {
                        //     const cdBattleEvent = cdBattleEventsData[startTime]
                        //     commit('setCDBattleEventById', { startTime, cdBattleEvent })
                        // })
                    }
                    if (responseBody.users) {
                        const usersData = extractUsersData(responseBody.users)
                        Object.keys(usersData).forEach((id) => {
                            const userData = usersData[id]
                            commit('setUserById', {id, userData})
                        })
                    }
                } else {
                    console.error(await response.json())
                }
                // return response.data
            } catch (error) {
                console.error(error.message)
            }
        },
    },
}

function getUrlApi() {
    return import.meta.env.VITE_STATS_API_URL ? import.meta.env.VITE_STATS_API_URL : undefined
}

function extractData(data) {
    const result = []
    if (isArray(data)) {
        data.forEach((item, key) => {
            result[key] = createCDData(item)
        })
    }

    return result
}

function extractUsersData(data) {
    const result = []
    if (isArray(data)) {
        data.forEach((item) => {
            result[item.id] = item
        })
    }

    return result
}

function getUserPositions(event) {
    if (isObject(event['userPositions'])) {
        return event['userPositions']
    }

    return null
}

function getTownPositions(event) {
    if (isObject(event['townPositions'])) {
        return event['townPositions']
    }

    return null
}

function getChestPositions(event) {
    if (isObject(event['chestPositions'])) {
        return event['chestPositions']
    }

    return null
}

function getVisiblePositions(event) {
    if (isArray(event['visibleLevels'])) {
        return event['visibleLevels']
    }

    return null
}


function extractCDBattleEventsData(data) {
    const result = []
    if (isArray(data)) {
        data.forEach((item) => {
            result[item.startTime] = item
        })
    }

    return result
}