<template>
  <div v-if="stars===6" class="hero-stars big-star">
    <img :src="bigStarIcon"/>
  </div>
  <div v-else class="hero-stars">
    <img v-for="ind in stars" :key="ind" :src="starIcon"/>
  </div>
</template>

<script>

export default {
  name: `c-hero-stars`,
  props: {
    stars: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      bigStarIcon: '/img/bigStarIcon.png',
      starIcon: '/img/starIcon.png',
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style>

.hero-stars {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
  height: 10px;

  z-index: 100;
  margin-bottom: 3px;
}

.hero-stars img {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
}

.hero-stars.big-star {
  height: 22px;
  left: 21px;
  bottom: 1px;
}

.hero-stars.big-star img {
  height: 18px;
  width: 28px;
}

</style>
