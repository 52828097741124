import './assets/main.css'
import './index.css'

import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store/index';
import * as Sentry from "@sentry/vue";

const app = createApp(App)

// const storedToken = localStorage.getItem('token');
// if (storedToken) {
//     // Здесь нужно реализовать проверку срока действия токена
//     // Если токен действителен, установите его в состоянии Vuex
//     store.commit('setToken', storedToken);
// } else {
//     router.push('/login');
// }

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],

    enableTracing: true,

    tracesSampleRate: 1.0,
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    tracePropagationTargets: ["192.168.1.66", /^https:\/\/st\.proxima-tech\.ru\/api\/v1/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    profilesSampleRate: 1.0,
});


app.use(router).use(store).mount('#app')