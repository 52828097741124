<template>
  <div id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="overlay overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Login
          </h3>
        </div>
        <!-- Modal body -->
        <div class=" grid grid-cols-1 gap-1 p-2 lg:grid-cols-2 xl:grid-cols-4">
          <form>
            <div class="form mb-4">
              <label for="userId" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">userId</label>
              <input type="text" id="userId" v-model="userId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">password</label>
              <input type="password" id="password" v-model="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
            </div>
            <button @click="auth" :disabled="isButtonDisabled" type="submit" :class="{ 'cursor-not-allowed': isButtonDisabled }" class="mx-4 mt-7 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>

<!--  </div>-->
<!--  <div v-else>-->
<!--    <c-avatar :avatarId="avatarId" :frameId="frameId" :userName="userName" :teamLevel="teamLevel" />-->
<!--  </div>-->
</template>

<script>
// import Avatar from '@src/components/Avatar.vue'
import {isAuthenticated, login} from './auth/auth.ts'

export default {
  props: ['show'],
  data() {
    return {
      isButtonDisabled: false,
      userId: '',
      password: '',
      // avatarId: '',
      // frameId: '',
      // userName: '',
      // teamLevel: '',
    };
  },
  computed: {
    // avatarId() {
    //   return this.getTokenPayload && this.getTokenPayload.avatarId
    // },
    // frameId() {
    //   return this.getTokenPayload && this.getTokenPayload.frameId
    // },
    // userName() {
    //   return this.getTokenPayload && this.getTokenPayload.userName
    // },
    // teamLevel() {
    //   return this.getTokenPayload && this.getTokenPayload.teamLevel
    // },
  },
  methods: {
    async auth() {
      this.isButtonDisabled = true
      try {
        const token = await login(this.userId, this.password);

        if (token) {
          this.$store.commit('setToken', token);
          localStorage.setItem('token', token);

          if (isAuthenticated()) {
            this.closeDialog()
          }
        }
      } catch (err) {
        console.error('Ошибка при аутентификации:', err);
        error.value = 'Error during login';
        // Handle the error or display a message to the user
      } finally {
        this.isButtonDisabled = false;
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}

.form {
  display: inline-block;
  vertical-align: top;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/*.dialog {*/
/*  position: fixed;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  !* Добавьте стили для вашего диалогового окна *!*/
/*}*/

</style>
