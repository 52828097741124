<template>
  <c-init-defs />
  <nav class="bg-white border-gray-200 dark:bg-gray-900">
    <RouterLink to="/arena">Arena</RouterLink>
    <RouterLink to="/ga">Grand Arena</RouterLink>
    <RouterLink to="/cw">Clan War</RouterLink>
    <RouterLink to="/stats">Statistics Clan War</RouterLink>
    <RouterLink v-if="isDevMode" to="/cd">Clan Domination</RouterLink>
    <RouterLink to="/auth">Auth</RouterLink>
    <!--        <RouterLink to="/signup">Signup</RouterLink>-->
  </nav>
  <main role="main" class="w-12/12 h-full p-2">
    <c-auth v-if="!isAuthenticatedStatus" @close="isAuthenticatedStatus = true" />
    <RouterView />
  </main>
</template>

<script>
import Auth from "./components/Auth.vue";
import InitDefs from "./components/InitDefs.vue";

import {isAuthenticated} from "./components/auth/auth.ts";

export default {
  components: {
    'c-auth': Auth,
    'c-init-defs': InitDefs,
  },
  data() {
    return {
      isAuthenticatedStatus: this.isAuthenticated(),
    };
  },
  computed: {
    isDevMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
  watch: {
    isAuthenticatedStatus() {
      this.isAuthenticatedStatus = this.isAuthenticated();
    },
  },
  methods: {
    isAuthenticated() {
      return isAuthenticated();
    },
  },
};
</script>

<style>
@import '@src/assets/base.css';

/*#app {*/
/*  min-width: 300px;*/
/*  max-width: 500px;*/
/*  margin: 0 auto;*/
/*  padding: 1.0rem;*/
/*  font-weight: normal;*/
/*}*/

/*header {*/
/*  line-height: 1.5;*/
/*  max-height: 100vh;*/
/*}*/

/*.logo {*/
/*  display: block;*/
/*  margin: 0 auto 2rem;*/
/*}*/

a, .green {
  text-decoration: none;
  color: hsla(160, 100%, 37%, 1);
  transition: 0.4s;
}

@media (hover: hover) {
  a:hover {
    background-color: hsla(160, 100%, 37%, 0.2);
  }
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }

  /*#app {*/
  /*  display: grid;*/
  /*  grid-template-columns: 1fr 1fr;*/
  /*  padding: 0 2rem;*/
  /*}*/

  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>