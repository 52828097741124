<template>
  <div class="d-block">
    <div class="pet-icon">
      <img :src="petIconBorder" class="border" />
      <img :src="petIcon" class="image" :class="petIconBackgroundColor" />
      <c-hero-level :level="petLevel" :color="petIconBackgroundColor" />
      <c-hero-stars :stars="petStars" />
    </div>
    <c-item-xp v-if="petInfo" :xp="petInfo.power"/>
  </div>

</template>

<script>
import HeroStars from "@src/components/hero/HeroStars.vue";
import HeroLevel from "@src/components/hero/HeroLevel.vue";
import ItemXp from "@src/components/hero/ItemXp.vue";
import { convertColorToName, convertColorToShortName } from "@src/components/common.ts";

export default {
  name: `c-pet-item`,
  components: {
    'c-hero-stars': HeroStars,
    'c-hero-level': HeroLevel,
    'c-item-xp': ItemXp,
  },
  props: {
    petInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    petIcon() {
      return '/img/' + 'pet_160_' + this.petInfo.id + '.png';
    },
    petIconBackgroundColor() {
      return this.convertColorToShortName(this.petInfo.color)
    },
    petIconBorder() {
      const colorName = this.convertColorToName(this.petInfo.color)
      return '/img/border_pet_' + colorName + '.png'
    },
    petLevel() {
      return this.petInfo.level
    },
    petStars() {
      // return 5
      return this.petInfo.star
    },
  },
  methods: {
    convertColorToName,
    convertColorToShortName
  },
  mounted() {
  },
}
</script>

<style scoped>

.white {
  background-color: red;
}

.green {
  background-color: red;
}

.blue {
  background-color: red;
}

.violet {
  background-color: #ad3a90;
}

.gold {
  background-color: red;
}

.red {
  background-color: red;
}

.d-block {
  display: inline-block;
}

.d-block {
  display: inline-block;
}

.image-frame {
  margin-left: 2px;
  margin-top: 2px;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  display: block;

}

.pet-icon {
  /*display: inline-block;*/
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  /*align-items: center;*/
  /*position: relative;*/

  width: 58px;
  height: 58px;
  /*border: 1px solid #000; !* Рамка для контейнера *!*/
}

img {
  vertical-align: middle;
  border-style: none;
}

img.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*border: 1px solid #000; !* Рамка для изображений *!*/

  z-index: 50;
}

img.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  margin: 5px;
  border-radius: 45%;
}
</style>
