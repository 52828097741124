<template>
  <div v-if="isOpen" @click="closeModal" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[500]">
    <div @click.stop class="bg-white p-6 rounded-lg shadow-lg w-[80%] h-[80%] sm:w-[80%] sm:h-[80%] lg:w-[60%] lg:h-[70%] xl:w-[60%] xl:h-[70%] flex flex-col">
      <h2 class="text-xl font-bold mb-4">
        <slot name="title">Модальное окно</slot>
      </h2>

      <div class="flex-1 overflow-y-auto">
        <slot></slot>
      </div>

      <div v-if="$slots.footer || showDefaultButton" class="mt-4">
        <slot name="footer">
          <button @click="closeModal" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Закрыть
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: `c-modal`,
    // components: {
    //   'c-clan-icon': ClanIcon,
    //   'c-hero-pack': HeroPack,
    //   'c-titan-pack': TitanPack,
    // },
    props: {
      isOpen: {
        type: Boolean,
        required: true
      },
      showDefaultButton: {
        type: Boolean,
        default: true
      },
      // battleInfo: {
      //   type: Object,
      //   require: true,
      // },
    },
    data() {
      return {
        // heroIcon: '/img/0004.png',
        // heroIconBorder: '/img/red_2.png',
        // bigStarIcon: '/img/bigStarIcon.png',
        // heroLevel: '/img/level_red.png',
        // patronagePetIcon: '/img/6007.png',
      };
    },
    emits: ['close'],
    methods: {
      closeModal() {
        this.$emit('close');
      }
    },
    computed: {
      // getTable1() {
      //   return this.battleInfo.tableData1
      // },
      // attackScoreData() {
      //   return this.battleInfo.attackScoreData
      // },
      // getTable2() {
      //   return this.battleInfo.tableData2
      // },
      // opponentAttackScoreData() {
      //   return this.battleInfo.opponentAttackScoreData
      // },
      // aggregateAttackScoreData() {
      //
      //   let sumPoints = 0
      //   let sumStrikes = 0
      //   this.attackScoreData.forEach((item) => {
      //     sumPoints += item.points
      //     sumStrikes += item.strikes
      //   })
      //
      //   return {'points': Math.round(sumPoints),'strikes': sumStrikes}
      // },
      // aggregateOpponentAttackScoreData() {
      //
      //   let sumPoints = 0
      //   let sumStrikes = 0
      //   this.opponentAttackScoreData.forEach((item) => {
      //     sumPoints += item.points
      //     sumStrikes += item.strikes
      //   })
      //
      //   return {'points': Math.round(sumPoints),'strikes': sumStrikes}
      // },
    },
    // methods: {
    //   // isHero(item) {
    //   //   return item && item.type && item.type === 'hero'
    //   // },
    //   // isPet(item) {
    //   //   return item && item.type && item.type === 'pet'
    //   // },
    //   // isTitan(item) {
    //   //   return item && item.type && item.type === 'titan'
    //   // },
    //   // getBgColor(item) {
    //   //   switch (item.cssStyle) {
    //   //     case 'red':
    //   //       return 'bg-rose-300'
    //   //     case 'blue':
    //   //       return 'bg-sky-300'
    //   //     case 'green':
    //   //       return 'bg-emerald-300'
    //   //     default:
    //   //       return ''
    //   //   }
    //   // },
    // },
    mounted() {
    },
}
</script>

<style scoped>
.hero-pack {
    width: 500px;
}
.inline {
    display: inline-block;
}

</style>
