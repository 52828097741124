<template>
  <div class="greetings">
    <h1 class="green">Statistic Clan War</h1>
  </div>
  <div>
    <div class="w-12/12 p-4">
      <div class="px-1 py-2 text-ls text-left text-black">Week</div>
      <c-dropdown
          :optionList="weekTitleList"
          @option-selected="handleOptionSelectedWeek"
          filterMode="last"
          :filterSize="20"
      />
      <c-clan-war-stats-table
          :statsInfo="statsInfo"
          :statsTotalInfo="statsTotalInfo"
          :userList="users"
      />
    </div>
  </div>
</template>

<script>
// import { createClanWarData } from '@src/components/ClanWar.ts'
import ClanWarStatsTable from '@src/components/ClanWarStatsTable.vue'
import DropDown from '@src/components/DropDown.vue'
import {/*StatsResponse, StatsEvent,*/ createUserStats, createGroupedUserStats, getFirstMondayDate} from '@src/components/stats.ts'
import {getToken, isAuthenticated, getTokenPayload} from "@src/components/auth/auth.ts";

export default {
  components: {
    'c-clan-war-stats-table': ClanWarStatsTable,
    'c-dropdown': DropDown,
  },
  data() {
    return {
      statsInfo: {},
      statsTotalInfo: {},
      users: {},
      // weekTitleList: [],
    };
  },
  computed: {
    getTokenPayload() {
      return getTokenPayload()
    },
    clanId() {
      return this.getTokenPayload && this.getTokenPayload.clanId
    },
    weekTitleList() {
      // const currentYear = 2023
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear()
      const weeksArray = []
      let weekCount = 1
      let startDate = getFirstMondayDate(currentYear)

      while (startDate < currentDate) {
        let endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + 6)

        const weekItem = {
          item: `${weekCount} (${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()})`,
          value: `${currentYear}_${weekCount}`
        }
        weeksArray.push(weekItem);

        // Увеличиваем дату на 7 дней, учитывая переходы через месяцы и годы
        startDate = new Date(startDate.setDate(startDate.getDate() + 7));
        weekCount++;
      }

      return weeksArray;
    },
  },
  methods: {
    async sendRequestSelectClanWarTitleList(year, week) {
      if (!isAuthenticated()) {
        return
      }
      this.isButtonDisabled = true

      await fetch(this.urlApi + 'cw_stats/year/' + year + '/week/' + week, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken(),
        },
      }).then((response) => {
        this.isButtonDisabled = false
        if (response.status !== undefined) {
          if (response.status === 200 && response.body !== undefined) {
            return response.json()
          } else {
            throw new Error('invalid response: ' + response)
          }
        } else {
          throw new Error('invalid response: ' + response)
        }
      }).then((jsonResponse) => {
        if (jsonResponse !== undefined && jsonResponse.Attack && jsonResponse.Defence) {
          const statsAttackInfo = createUserStats(jsonResponse.Attack, 'AttackerID')
          const statsDefenceInfo = createUserStats(jsonResponse.Defence, 'DefenderID')
          const totalStatsAttackInfo = createGroupedUserStats(jsonResponse.Attack, 'AttackerID')
          const totalStatsDefenceInfo = createGroupedUserStats(jsonResponse.Defence, 'DefenderID')

          this.statsInfo = {"attack": statsAttackInfo, "defence": statsDefenceInfo}
          this.statsTotalInfo = {"attack": totalStatsAttackInfo, "defence": totalStatsDefenceInfo}
        }
        if (jsonResponse !== undefined && jsonResponse.Users) {
          this.users = jsonResponse.Users
        }
      }).catch((error) => {
        this.isButtonDisabled = false
        console.log(error)
      })
    },
    createBattleResult(score1, score2) {
      if (score1 > score2) {
        return "<b class='text-emerald-700'>win</b>"
      } else if (score1 < score2) {
        return "<b class='text-rose-700'>loss</b>"
      } else {
        return "<b class='text-blue-700'>draw</b>"
      }
    },
    createClanWarTitleList(response) {
      const result = []
      const self = this
      response.forEach(function (item, key) {
        const resultBattle = self.createBattleResult(item.score[0], item.score[1])

        result[key] = {
          item: "<span>" + item.date + " " + item.clanName + " / " + item.enemyClanName + "  [" + item.score[0] + " : " + item.score[1] + "] " + resultBattle + "</span>",
          value: item.date
        }
      })

      return result
    },
    handleOptionSelectedWeek(option) {
      const options = option.value.split('_')
      const year = options[0]
      const week = options[1]

      this.sendRequestSelectClanWarTitleList(year, week).then((jsonResponse) => {
        this.resp1 = jsonResponse
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.urlApi = import.meta.env.VITE_STATS_API_URL ? import.meta.env.VITE_STATS_API_URL : undefined

    const weekTitleListLength = this.weekTitleList.length

    if (weekTitleListLength > 0) {
      const options = this.weekTitleList[weekTitleListLength - 1].value.split('_')
      const year = options[0]
      const week = options[1]

      const self = this
      this.$nextTick(() => {
        self.sendRequestSelectClanWarTitleList(year, week)
      });
    }

  },
}
</script>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
