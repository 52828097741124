<template>
  <div class="hero-pack">
    <div v-for="(item, ind) in packInfo" :key="ind" class="hero-item">
      <c-hero-item
          v-if="isHeroType(item)"
          :heroInfo = "item"
      />
      <c-pet-item
          v-else-if="isPetType(item)"
          :petInfo = "item"
      />
    </div>
    <c-banner-item
        v-if="getNewBannerInfo"
        :bannerInfo = "getNewBannerInfo"
        class="hero-item"
    />
  </div>
</template>

<script>
import HeroItem from "@src/components/hero/HeroItem.vue"
import PetItem from "@src/components/hero/PetItem.vue";
import BannerItem from "@src/components/hero/BannerItem.vue";

export default {
  name: `c-hero-pack`,
  components: {
    'c-hero-item': HeroItem,
    'c-pet-item': PetItem,
    'c-banner-item': BannerItem,
  },
  props: {
    packInfo: {
      type: Object,
      require: true,
    },
    bannerInfo: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    getInfo() {
      return this.packInfo
    },
    getNewBannerInfo() {
      if (this.bannerInfo) {
        return this.bannerInfo
      }

      return undefined
    }
  },
  methods: {
    isPetType(item) {
      if (item !== undefined && item.type !== undefined) {
        return item.type ==='pet'
      }

      return false
    },
    isHeroType(item) {
      if (item !== undefined) {
        //TODO При отсутствии типа hero по умолчанию
        if (item.type === undefined) {
          return true
        }
        return item.type ==='hero'
      }

      return false
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.hero-pack {
  /*width: 350px;*/
  height: 100px;
}
.hero-item {
  display: inline-block;
  vertical-align: top;
}

</style>
