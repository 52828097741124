<template>
  <div class="relative pt-1">
    <div :class="bgClass">
      <div :style="styleWidth" :class="progressClass"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: `c-progress-bar`,
  // components: {
  // },
  props: {
    progressPercent: {
      type: Number,
      require: true,
    },
    bgColor: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    styleWidth() {
      return 'width: ' + this.progressPercent + '%';
    },
    bgClass() {
      return 'overflow-hidden h-2 text-xs flex rounded bg-' + this.bgColor + '-200';
    },
    progressClass() {
      return 'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-'+this.bgColor+'-500';
    },
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>
