<template>
  <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
    <table class="table-fixed">
      <thead>
      <tr>
        <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
          #</th>
        <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          ATTACKER</th>
        <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          DEFENDER</th>
        <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          FORTNAME</th>
        <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          RES</th>
        <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          POINTS</th>
        <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
          DATETIME</th>
      </tr>
      </thead>
      <tbody v-for="(list, indList) in filteredHistoryBattleEvents" :key="indList" class="bg-white">
      <tr v-for="(item, ind) in list.tableData1" :key="ind" :class="getBgColor(item)">
        <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
          {{ind+1}}
        </td>
        <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
          <span class="p-2 text-black"><b>{{item.attacker}}</b></span>
          <span class="p-2 text-black">{{item.attackerPower}}</span>
          <c-titan-pack v-if="isTitan(item.attackers[0])" :packInfo="item.attackers" style="width: 360px;"/>
          <c-hero-pack v-else-if="isHero(item.attackers[0]) || isPet(item.attackers[0])" :packInfo="item.attackers" :bannerInfo="item.attackerBanner" style="width: 420px;"/>
        </td>
        <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
          <span class="p-2 text-black"><b>{{item.defender}}</b></span>
          <span class="p-2 text-black">{{item.defenderPower}}</span>
          <c-titan-pack v-if="isTitan(item.defenders[0])" :packInfo="item.defenders" style="width: 360px;"/>
          <c-hero-pack v-else-if="isHero(item.defenders[0]) || isPet(item.defenders[0])" :packInfo="item.defenders" :bannerInfo="item.defenderBanner"  style="width: 420px;"/>
        </td>
        <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
          {{item.fortName}}
        </td>
        <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 40px;">
          {{item.res}}
        </td>
        <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
          {{item.points}}
        </td>
        <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200">
          {{list.date}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import HeroPack from "@src/components/hero/HeroPack.vue";
import TitanPack from "@src/components/hero/TitanPack.vue";
import ClanIcon from "@src/components/ClanIcon.vue";

import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: `c-history-battle-events`,
    components: {
      'c-clan-icon': ClanIcon,
      'c-hero-pack': HeroPack,
      'c-titan-pack': TitanPack,
    },
    props: {
      clanId: {
        type: String,
        required: true
      },
      userId: {
        type: String,
        required: true
      },
      battleEventType: {
        type: String,
        required: true
      },
      packType: {
        type: String,
        required: true
      }
    },
    // data() {
    //   return {
    //   };
    // },
    setup(props) {
      const store = useStore()

      const key = props.clanId + '_' + props.userId
      const historyBattleEvents = computed(() => store.getters['app/getHistoryBattleEvents'](key))

      if (!historyBattleEvents.value) {
        try {
          // await store.dispatch('fetchTableData', props.userId);
          store.dispatch(
              'app/fetchHistoryBattleEvents',
              {clanId: props.clanId, userId: props.userId, battleEventType: props.battleEventType}
          )
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error)
        }
      }

      const filteredHistoryBattleEvents = computed(() => {
        if (!historyBattleEvents.value) return []

        return historyBattleEvents.value.map(event => ({
          ...event,
          tableData1: event.tableData1.filter(item =>
              props.packType === 'titan' ? isTitan(item.attackers[0]) :
              props.packType === 'hero' ? isPet(item.attackers[0]) || isHero(item.attackers[0]) : true
          )
        }))
      })

      const isHero = (item) => {
        return item && item.type && item.type === 'hero'
      }
      const isPet = (item) => {
        return item && item.type && item.type === 'pet'
      }
      const isTitan = (item) => {
        return item && item.type && item.type === 'titan'
      }
      const getBgColor = (item) => {
        switch (item.cssStyle) {
          case 'red':
            return 'bg-rose-300'
          case 'blue':
            return 'bg-sky-300'
          case 'green':
            return 'bg-emerald-300'
          default:
            return ''
        }
      }

      return {
        historyBattleEvents,
        filteredHistoryBattleEvents,
        isHero,
        isPet,
        isTitan,
        getBgColor,
      }
    },
    // computed: {
    // },
    // methods: {
    //
    // },
    mounted() {
    },
}
</script>

<style scoped>
.hero-pack {
    width: 500px;
}
.inline {
    display: inline-block;
}

</style>
