<template>
  <div class="lvl">
    <img :src="levelBackground"/>
    <span>{{level}}</span>
  </div>
</template>

<script>

export default {
  name: `c-team-level`,
  props: {
    level: {
      type: [String, Number],
      require: true,
    },
    color: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    levelBackground() {
      return '/img/level_' + this.color + '.png'
    },
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>

.lvl {
  flex: 1;
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: flex-end; /* Размещение в нижней части */
  width: 32px;
  margin: 0 auto;

  z-index: 100;
  position: relative;
}

.lvl img {
  position: absolute;
  /*top: 0;*/
  /*left: 0;*/
  width: 100%;
}

.lvl span {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  font-weight: bold;
  font-family: Arial,sans-serif;
  font-size: 13px;
  text-align: center;

  z-index: 101;
}

</style>
