<template>
  <div class="greetings">
    <h1 class="green">Clan War</h1>
  </div>
  <div class="flex">
    <div class="w-full md:w-full lg:w-full xl:w-1/2 p-4">
      <c-dropdown :optionList="clanWarTitleList" @option-selected="handleOptionSelected1" />
      <c-clan-war-battle-table
          v-if="getClanWarBattleData1"
        :battleInfo="getClanWarBattleData1" />
    </div>
    <div class="w-full md:w-full lg:w-full xl:w-1/2 p-4">
      <c-dropdown :optionList="clanWarTitleList" @option-selected="handleOptionSelected2" />
      <c-clan-war-battle-table
          v-if="getClanWarBattleData2"
          :battleInfo="getClanWarBattleData2" />
    </div>
  </div>
</template>

<script>
import { createClanWarData } from '@src/components/ClanWar.ts'
import ClanWarBattleTable from '@src/components/ClanWarBattleTable.vue'
import DropDown from '@src/components/DropDown.vue'
import {
  cloneDeep,
} from "lodash"
import { getToken, isAuthenticated, getTokenPayload } from "@src/components/auth/auth.ts";

export default {
  components: {
    'c-clan-war-battle-table': ClanWarBattleTable,
    'c-dropdown': DropDown,
  },
  data() {
    return {
      resp1: undefined,
      resp2: undefined,
      clanWarTitleList: [],
    };
  },
  computed: {
    getClanWarBattleData1() {
      if (!this.resp1) {
        return undefined
      }

      return createClanWarData(cloneDeep(this.resp1))
    },
    getClanWarBattleData2() {
      if (!this.resp2) {
        return undefined
      }

      return createClanWarData(cloneDeep(this.resp2))
    },
    getTokenPayload() {
      return getTokenPayload()
    },
    clanId() {
      return this.getTokenPayload && this.getTokenPayload.clanId
    },
  },
  methods: {
    async sendRequestClanWar(date) {
      if (!isAuthenticated()) {
        return
      }
      this.isButtonDisabled = true

      return await fetch(this.urlApi + 'cw/date/' + date, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken(),
        },
      }).then((response) => {
        this.isButtonDisabled = false
        if (response.status !== undefined) {
          if (response.status === 200 && response.body !== undefined) {
            return response.json()
          } else {
            throw new Error('invalid response: ' + response)
          }
        } else {
          throw new Error('invalid response: ' + response)
        }
      }).then((jsonResponse) => {
        return jsonResponse
      }).catch((error) => {
        this.isButtonDisabled = false
        console.log(error)
      })
    },
    async sendRequestSelectClanWarTitleList() {
      if (!isAuthenticated()) {
        return
      }
      this.isButtonDisabled = true

      await fetch(this.urlApi + 'cw_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken(),
        },
      }).then((response) => {
        this.isButtonDisabled = false
        if (response.status !== undefined) {
          if (response.status === 200 && response.body !== undefined) {
            return response.json()
          } else {
            throw new Error('invalid response: ' + response)
          }
        } else {
          throw new Error('invalid response: ' + response)
        }
      }).then((jsonResponse) => {
        this.clanWarTitleList = this.createClanWarTitleList(jsonResponse)
      }).catch((error) => {
        this.isButtonDisabled = false
        console.log(error)
      })
    },
    createBattleResult(score1, score2) {
      if (score1 > score2) {
        return "<b class='text-emerald-700'>win</b>"
      } else if (score1 < score2) {
        return "<b class='text-rose-700'>loss</b>"
      } else {
        return "<b class='text-blue-700'>draw</b>"
      }
    },
    createClanWarTitleList(response) {
      const result = []
      const self = this
      response.forEach(function (item, key) {
        const resultBattle = self.createBattleResult(item.score[0], item.score[1])

        result[key] = {
          item: "<span>" + item.date + " " + item.clanName + " / " + item.enemyClanName + "  [" + item.score[0] + " : " + item.score[1] + "] " + resultBattle + "</span>",
          value: item.date
        }
      })

      return result
    },
    handleOptionSelected1(option) {
      this.sendRequestClanWar(option.value).then((jsonResponse) => {
        this.resp1 = jsonResponse
      }).catch((error) => {
        console.log(error)
      })
    },
    handleOptionSelected2(option) {
      this.sendRequestClanWar(option.value).then((jsonResponse) => {
        this.resp2 = jsonResponse
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.urlApi = import.meta.env.VITE_STATS_API_URL ? import.meta.env.VITE_STATS_API_URL : undefined

    const self = this
    this.$nextTick(() => {
      self.sendRequestSelectClanWarTitleList()
    });
  },
}
</script>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
