import { getToken } from "@src/components/auth/auth.ts"
import { createClanWarData } from '@src/components/ClanWar.ts'
import { isArray } from "lodash"

export const appModule = {
    namespaced: true, // Используем пространственные имена
    state: () => ({
        historyBattleEvents: {}
    }),
    getters: {
        getHistoryBattleEvents: (state) => (key) => {
            return state.historyBattleEvents[key] || null
        }
    },
    mutations: {
        setHistoryBattleEvents(state, { key, data }) {
            state.historyBattleEvents = {
                ...state.historyBattleEvents,
                [key]: data
            }
        },
    },
    actions: {
        async fetchHistoryBattleEvents({ commit, state }, { clanId, userId, battleEventType }) {
            const key = clanId + '_' + userId

            if (state.historyBattleEvents[key]) {
                return state.historyBattleEvents[key]
            }

            try {
                validateParameters(clanId, userId, battleEventType)

                const response = await fetch(getUrlApi() + 'battle_events/clan/' + clanId + '/user/' + userId + '/type/' + battleEventType, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken(),
                    },
                    // body: JSON.stringify(payload)
                })
                const data = extractData(await response.json())
                commit('setHistoryBattleEvents', { key, data })

                return response.data
            } catch (error) {
                console.error(error.message)
            }
        },
    },
}

function getUrlApi() {
    return import.meta.env.VITE_STATS_API_URL ? import.meta.env.VITE_STATS_API_URL : undefined
}

function extractData(data) {
    const result = []
    if (isArray(data)) {
        data.forEach((item, key) => {
            result[key] = createClanWarData(item)
        })
    }

    return result
}

function validateParameters(numericParam1, numericParam2, enumParam) {
    const isIntegerOrStringNumber = (param) =>
        (typeof param === 'number' && Number.isInteger(param)) ||
        (typeof param === 'string' && /^[+-]?\d+$/.test(param))

    if (!isIntegerOrStringNumber(numericParam1)) {
        throw new Error(`Invalid parameter: The first parameter must be a numeric value, given: ${numericParam1}.`)
    }

    if (!isIntegerOrStringNumber(numericParam2)) {
        throw new Error(`Invalid parameter: The second numeric parameter must be a numeric value, given: ${numericParam2}.`)
    }

    const validEnumValues = ['ally', 'enemy']
    if (!validEnumValues.includes(enumParam)) {
        throw new Error(`Invalid parameter: The third parameter must be one of the following values: ${validEnumValues.join(', ')}, given: ${enumParam}.`)
    }

    return true
}