<template>
  <div class="d-block">
    <c-banner-icon :id="bannerId" width="60" height="60" class="icon"/>
  </div>

</template>

<script>
import BannerIcon from "@src/components/hero/BannerIcon.vue";

export default {
  components: {
    'c-banner-icon': BannerIcon,
  }
}
</script>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  bannerInfo: {
    type: Object,
    required: true,
  },
})

const bannerId = computed(() => {
  return String(props.bannerInfo.id)
})

</script>

<style scoped>

.icon {
  padding-top: 4px;
  padding-left: 4px;
  width: 56px;
  height: 56px;
}
</style>
