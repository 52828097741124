<template>
  <div class="flex flex-col mt-8">
    <div class="flex items-center space-x-4">
      <label>
        <input type="checkbox" v-model="attackChecked" @change="onCheckboxChange('attack')" />
        Attack
      </label>
      <label>
        <input type="checkbox" v-model="defenseChecked" @change="onCheckboxChange('defense')" />
        Defense
      </label>
    </div>
  </div>
</template>

<script>

export default {
    name: `c-battle-event-filter`,
    props: {
      initialAttackChecked: {
        type: Boolean,
        default: true,
      },
      initialDefenseChecked: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        attackChecked: this.initialAttackChecked,
        defenseChecked: this.initialDefenseChecked,
      };
    },
    computed: {
    },
    watch: {
      attackChecked(val) {
        this.$emit('filter-change', { attackChecked: val, defenseChecked: this.defenseChecked });
      },
      defenseChecked(val) {
        this.$emit('filter-change', { attackChecked: this.attackChecked, defenseChecked: val });
      },
    },
    methods: {
      onCheckboxChange(type) {
        if (type === 'attack' && !this.attackChecked && !this.defenseChecked) {
          this.defenseChecked = true;
        } else if (type === 'defense' && !this.attackChecked && !this.defenseChecked) {
          this.attackChecked = true;
        }
      },
    },
    mounted() {
    },
}
</script>

<style scoped>
</style>
