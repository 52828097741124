import {isArray} from "lodash"

export interface StatsResponse {
    Attack: StatsEvent[]
    Defence: StatsEvent[]
    Users: StatsUserList
}

export interface StatsEvent {
    Time: number
    Date: string
    SlotID: string
    PreviousStatus: string
    AttackerID: string
    AttackerTotalPower: number
    DefenderID: string
    DefenderTotalPower: number
    Win: boolean,
    FortificationPoints: number
    SlotPoints: number
    Type: string
}

export interface StatsUserList {
    [id: string]: StatsUser
}

export interface StatsByUserId{
    [id: string]: StatsEvent[]
}

export interface StatsUser {
    ID: number
    Name: string
    ServerID: number
    Level: number
    ClanID: number
    ClanRole: number
    Commander: true,
    AvatarID: number
    FrameID: number
    LeagueID: number
    ClanTitle: string
}

export interface TotalUserStats {
    userName: string
    points: number
    strikes: number
    wins: number
    attackerTitanSumPower: number
    defenderTitanSumPower: number
    attackerHeroSumPower: number
    defenderHeroSumPower: number
}

export function createUserStats(data: StatsEvent[], keyName: string) {
    if (data === undefined || !isArray(data)) {
        return {}
    }

    const groupedByDateAndUser = {}
    for (const item of data) {
        if (!groupedByDateAndUser[item.Date]) {
            groupedByDateAndUser[item.Date] = {}
        }

        if (!groupedByDateAndUser[item.Date][item[keyName]]) {
            groupedByDateAndUser[item.Date][item[keyName]] = []
        }

        groupedByDateAndUser[item.Date][item[keyName]].push(item)
    }

    return groupedByDateAndUser
}

export function createGroupedUserStats(data: StatsEvent[], keyName: string) {
    if (data === undefined || !isArray(data)) {
        return {}
    }

    const groupedByUser = {}
    for (const item of data) {
        if (!groupedByUser[item[keyName]]) {
            groupedByUser[item[keyName]] = []
        }

        groupedByUser[item[keyName]].push(item)
    }

    return groupedByUser
}

//TODO Хотел сделать универсальной, но тут нужно по 2м параметрам считать
export function aggregateSum(stats: StatsByUserId/*, key: string*/) {
    if (stats === undefined) {
        return 0
    }

    let sum = 0
    Object.keys(stats).forEach((userId) => {
        const userStats = stats[userId]
        userStats.forEach((item) => {
            if (item.SlotPoints) {
                sum += item.SlotPoints
            }
            if (item.FortificationPoints) {
                sum += item.FortificationPoints
            }
        })
    })

    return sum
}

export function createUserTotalStats(stats: StatsByUserId, userList: StatsUserList): TotalUserStats[] {
    if (stats === undefined) {
        return []
    }

    let result = []
    Object.keys(stats).forEach((userId) => {
        const itemUserStats = stats[userId]

        let user = findUserById(itemUserStats[0].AttackerID, userList)
        let pointSum = 0
        let attackerHeroSumPower = 0
        let defenderHeroSumPower = 0
        let attackerTitanSumPower = 0
        let defenderTitanSumPower = 0
        let winsSum = 0
        itemUserStats.forEach((item) => {
            pointSum += item.SlotPoints

            if (item.Type === "clan_pvp_titan") {
                attackerTitanSumPower += item.AttackerTotalPower
                defenderTitanSumPower += item.DefenderTotalPower
            } else {
                attackerHeroSumPower += item.AttackerTotalPower
                defenderHeroSumPower += item.DefenderTotalPower
            }
            if (item.Win) {
                winsSum++
            }
        })

        result.push({
            userName: user ? user.Name : 'none', points: pointSum, strikes: itemUserStats.length, wins: winsSum,
            attackerTitanSumPower: attackerTitanSumPower, defenderTitanSumPower: defenderTitanSumPower,
            attackerHeroSumPower: attackerHeroSumPower, defenderHeroSumPower: defenderHeroSumPower,
        })
    })
    return result
}

export function findUserById(userId: string, userList: StatsUserList): StatsUser | undefined {
    if (userList[userId]) {
        return userList[userId]
    }

    return undefined
}

export function isFairBattle(battles): boolean {
    let emptyPlayerStrikes = 0
    let playerStrikes = 0
    Object.keys(battles).forEach((key) => {
        if (key === '0') {
            emptyPlayerStrikes += battles[key].length
        } else {
            playerStrikes += battles[key].length
        }
    })

    return playerStrikes > emptyPlayerStrikes
}

export function getFirstMondayDate(year) {
    let firstMonday = new Date(year, 0, 1);
    while (firstMonday.getDay() !== 1) {
        firstMonday.setDate(firstMonday.getDate() + 1);
    }

    return firstMonday
}

export function getWeekDates(year, week) {
    const firstMonday = getFirstMondayDate(year)

    let startDate = new Date(firstMonday);
    startDate.setDate(startDate.getDate() + (week - 1) * 7);

    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    return { startDate, endDate };
}