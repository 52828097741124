<template>
  <div class="titan-pack">
    <div v-for="(item, ind) in packInfo" :key="ind" class="titan-item">
      <c-titan-item
          v-if="isTitanType(item)"
          :titanInfo = "item"
      />
    </div>
  </div>
</template>

<script>
import TitanItem from "@src/components/hero/TitanItem.vue";

export default {
  name: `c-titan-pack`,
  components: {
    'c-titan-item': TitanItem,
  },
  props: {
    packInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    getInfo() {
      return this.packInfo
    },
  },
  methods: {
    isTitanType(item) {
      if (item !== undefined && item.type !== undefined) {
        return item.type ==='titan'
      }

      return false
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.titan-pack {
  /*width: 400px;*/
  height: 100px;
}
.titan-item {
  display: inline-block;
  vertical-align: top;
}

</style>
