<template>
  <div class="flex flex-col mt-8">
    <div class="flex flex-col mt-2">
      <span class="text-xl font-bold">{{battleInfo.date}}</span>
    </div>
    <div class="flex items-center justify-between mt-2">
      <div class="inline">
        <c-clan-icon :clanIcon="battleInfo.attackGuild.clanIcon" :clanLeader="false" />
        <div class="text-lg font-bold text-center">{{battleInfo.attackGuild.name}}</div>
      </div>
      <div class="inline">
        <c-clan-icon :clanIcon="battleInfo.defGuild.clanIcon" :clanLeader="false" />
        <div class="text-lg font-bold text-center">{{battleInfo.defGuild.name}}</div>
      </div>
      <div class="inline">
        <span class="text-lg font-bold text-center">{{battleInfo.attackTotalPoints}} : {{battleInfo.defTotalPoints}}</span>
      </div>
    </div>
<!--    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2">-->
    <div class="py-2 -my-2 overflow-x-auto mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <thead>
            <tr>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                #</th>
              <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                ATTACKER</th>
              <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                DEFENDER</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                FORTNAME</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                RES</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                POINTS</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                FPOINTS</th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="(item, ind) in getTable1" :key="ind" :class="getBgColor(item)">
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{ind+1}}
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span @click="openModal(battleInfo.clanDefenderId, item.attackerId, item.attackers, item.attacker, 'ally')" class="p-2 text-black cursor-pointer"><b>{{item.attacker}}</b></span>
              <span class="p-2 text-black">{{item.attackerPower}}</span>
              <c-titan-pack v-if="isTitan(item.attackers[0])" :packInfo="item.attackers" style="width: 360px;"/>
              <c-hero-pack v-else-if="isHero(item.attackers[0]) || isPet(item.attackers[0])" :packInfo="item.attackers" :bannerInfo="item.attackerBanner" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span @click="openModal(battleInfo.clanDefenderId, item.defenderId, item.defenders, item.defender, 'enemy')" class="p-2 text-black cursor-pointer"><b>{{item.defender}}</b></span>
              <span class="p-2 text-black">{{item.defenderPower}}</span>
              <c-titan-pack v-if="isTitan(item.defenders[0])" :packInfo="item.defenders" style="width: 360px;"/>
              <c-hero-pack v-else-if="isHero(item.defenders[0]) || isPet(item.defenders[0])" :packInfo="item.defenders" :bannerInfo="item.defenderBanner" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.fortName}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 40px;">
              {{item.res}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.fPoints}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="py-2 -my-2 overflow-x-auto mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <thead>
            <tr>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                #</th>
              <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                NAME</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                POINTS</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                STRIKES</th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="(item, ind) in attackScoreData" :key="ind">
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{ind+1}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.name}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.strikes}}
            </td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              total
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{aggregateAttackScoreData.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{aggregateAttackScoreData.strikes}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="py-2 -my-2 overflow-x-auto mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <thead>
            <tr>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-center text-black uppercase border-b border-gray-200 bg-gray-300">
                #</th>
              <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                ATTACKER</th>
              <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                DEFENDER</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                FORTNAME</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                RES</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                POINTS</th>
              <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
                FPOINTS</th>
            </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="(item, ind) in getTable2" :key="ind" :class="getBgColor(item)">
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{ind+1}}
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span @click="openModal(battleInfo.clanDefenderId, item.attackerId, item.attackers, item.attacker, 'ally')" class="p-2 text-black cursor-pointer"><b>{{item.attacker}}</b></span>
              <span class="p-2 text-black">{{item.attackerPower}}</span>
              <c-titan-pack v-if="isTitan(item.attackers[0])" :packInfo="item.attackers" style="width: 360px;"/>
              <c-hero-pack v-else-if="isHero(item.attackers[0]) || isPet(item.attackers[0])" :packInfo="item.attackers" :bannerInfo="item.attackerBanner" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
              <span @click="openModal(battleInfo.clanDefenderId, item.defenderId, item.defenders, item.defender, 'enemy')" class="p-2 text-black cursor-pointer"><b>{{item.defender}}</b></span>
              <span class="p-2 text-black">{{item.defenderPower}}</span>
              <c-titan-pack v-if="isTitan(item.defenders[0])" :packInfo="item.defenders" style="width: 360px;"/>
              <c-hero-pack v-else-if="isHero(item.defenders[0]) || isPet(item.defenders[0])" :packInfo="item.defenders" style="width: 420px;"/>
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.fortName}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 40px;">
              {{item.res}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-center text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.fPoints}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="py-2 -my-2 overflow-x-auto mt-2">
      <div class="min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="table-fixed">
          <thead>
          <tr>
            <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
              #</th>
            <th class="w-1/5 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
              NAME</th>
            <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
              POINTS</th>
            <th class="w-1/12 px-1 py-2 text-xs leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-300">
              STRIKES</th>
          </tr>
          </thead>
          <tbody class="bg-white">
          <tr v-for="(item, ind) in opponentAttackScoreData" :key="ind">
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{ind+1}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              {{item.name}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{item.strikes}}
            </td>
          </tr>
          <tr>
            <td class="px-2 py-2 text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 60px;">
              total
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{aggregateOpponentAttackScoreData.points}}
            </td>
            <td class="px-2 py-2 text-sm leading-5 font-medium text-left text-black whitespace-no-wrap border-b border-gray-200" style="width: 20px;">
              {{aggregateOpponentAttackScoreData.strikes}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <c-modal :isOpen="isModalOpen" @close="closeModal" :showDefaultButton="false">
      <template #title>История сражений: {{selectedBattleTarget}}</template>
      <c-history-battle-events
          :clanId="selectedClanId"
          :userId="selectedUserId"
          :packType="selectedPackType"
          :battleEventType="selectedBattleEventType"
      />
<!--      <template #footer>
        <button @click="closeModal" class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
          Close
        </button>
      </template>-->
    </c-modal>
  </div>
</template>

<script>
import HeroPack from "@src/components/hero/HeroPack.vue";
import TitanPack from "@src/components/hero/TitanPack.vue";
import ClanIcon from "@src/components/ClanIcon.vue";
import Modal from "@src/components/Modal.vue";
import HistoryBattleEvents from "@src/components/HistoryBattleEvents.vue";
import { ref } from 'vue';

export default {
    name: `c-clan-war-battle-table`,
    components: {
      'c-clan-icon': ClanIcon,
      'c-hero-pack': HeroPack,
      'c-titan-pack': TitanPack,
      'c-modal': Modal,
      'c-history-battle-events': HistoryBattleEvents,
    },
    setup() {
      const isModalOpen = ref(false)
      const selectedClanId = ref(null)
      const selectedUserId = ref(null)
      const selectedPackType = ref(null)
      const selectedBattleEventType = ref(null)
      const selectedBattleTarget = ref(null)

      const openModal = (clanId, userId, packList, targetUserName, battleEventType) => {
        isModalOpen.value = true
        selectedClanId.value = clanId
        selectedUserId.value = userId
        selectedBattleEventType.value = battleEventType

        const action = battleEventType === 'enemy' ? 'защищается ' : 'атакует '
        selectedBattleTarget.value = action + targetUserName

        selectedPackType.value = isTitan(packList[0]) ? 'titan' :
            isPet(packList[0]) || isHero(packList[0]) ? 'hero' : undefined
      }

      const closeModal = () => {
        isModalOpen.value = false
      }

      const isHero = (item) => {
        return item && item.type && item.type === 'hero'
      }
      const isPet = (item) => {
        return item && item.type && item.type === 'pet'
      }
      const isTitan = (item) => {
        return item && item.type && item.type === 'titan'
      }
      const getBgColor = (item) => {
        switch (item.cssStyle) {
          case 'red':
            return 'bg-rose-300'
          case 'blue':
            return 'bg-sky-300'
          case 'green':
            return 'bg-emerald-300'
          default:
            return ''
        }
      }

      return {
        isModalOpen,
        selectedClanId,
        selectedUserId,
        selectedBattleEventType,
        selectedBattleTarget,
        selectedPackType,
        openModal,
        closeModal,
        isHero,
        isPet,
        isTitan,
        getBgColor,
      }
    },
    props: {
      battleInfo: {
        type: Object,
        require: true,
      },
    },
    // data() {
    //   return {
    //   };
    // },
    computed: {
      getTable1() {
        return this.battleInfo.tableData1
      },
      attackScoreData() {
        return this.battleInfo.attackScoreData
      },
      getTable2() {
        return this.battleInfo.tableData2
      },
      opponentAttackScoreData() {
        return this.battleInfo.opponentAttackScoreData
      },
      aggregateAttackScoreData() {

        let sumPoints = 0
        let sumStrikes = 0
        this.attackScoreData.forEach((item) => {
          sumPoints += item.points
          sumStrikes += item.strikes
        })

        return {'points': Math.round(sumPoints),'strikes': sumStrikes}
      },
      aggregateOpponentAttackScoreData() {

        let sumPoints = 0
        let sumStrikes = 0
        this.opponentAttackScoreData.forEach((item) => {
          sumPoints += item.points
          sumStrikes += item.strikes
        })

        return {'points': Math.round(sumPoints),'strikes': sumStrikes}
      },
    },
    // methods: {
    //   isHero(item) {
    //     return item && item.type && item.type === 'hero'
    //   },
    //   isPet(item) {
    //     return item && item.type && item.type === 'pet'
    //   },
    //   isTitan(item) {
    //     return item && item.type && item.type === 'titan'
    //   },
    //   getBgColor(item) {
    //     switch (item.cssStyle) {
    //       case 'red':
    //         return 'bg-rose-300'
    //       case 'blue':
    //         return 'bg-sky-300'
    //       case 'green':
    //         return 'bg-emerald-300'
    //       default:
    //         return ''
    //     }
    //   },
    // },
    mounted() {
    },
}
</script>

<style scoped>
.hero-pack {
    width: 500px;
}
.inline {
    display: inline-block;
}

</style>
