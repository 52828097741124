<template>
  <div class="relative">
    <div class="py-0 text-xs leading-4 text-center text-black">{{xp}}</div>
  </div>
</template>

<script>
export default {
  name: `c-item-xp`,
  props: {
    xp: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>
